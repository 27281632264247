import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import PageHeader from "../Comman/PageHeader";
import CandidatePay from "./CandidatePay";
import SubcontractorDetails from "./SubcontractorDetails";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import AddNewTimeLog from "./AddNewTimeLog";
import UploadInvoice from "./UploadInvoice";
import AddEditNotes from "./AddEditNotes";
import {
  getAllMonths, getAllYears, getFieldData, getFormatedNumber,
  getMonthLastDay, getMonthName, getTotalDays, getTotalHours
} from "../../Services/appService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTimeLogs, loadTimeLogs } from "../../app/store/timeLog";
import { getTimeLogInvoice, loadTimeLogInvoice } from "../../app/store/timeLogInvoice";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import useDocDownload from "../../app/hooks/useDocDownload";

export default function CandidateInvoice() {

  const route = [
    { name: "Contract Management", url: "" },
    { name: "Invoices and Payments", url: "/PartnerInvoices" },
  ];

  const [timeLogs, setTimeLogs] = useState([]);
  const [timeLogInvoices, setTimeLogInvoices] = useState([]);
  const [invType, setInvType] = useState("subcontractor");
  const [rate, setRate] = useState(0);
  const [performerData, setPerformerData] = useState("");
  const [entType, setEntType] = useState("");
  const [w9Type, setW9Type] = useState("");
  const [costBefenifit, setCostBefenifit] = useState("");
  const [candidateObj, setCandidateObj] = useState(null);
  const [notesType, setNotesType] = useState("TimeLog");
  const [selectedItem, setSelectedItem] = useState(null);
  const [noteShowHide, setNoteShowHide] = useState(false);
  const [timeLogObj, setTimeLogObj] = useState(null);
  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [showHide, setShowHide] = useState(false);
  const [payShowHide, setPayShowHide] = useState(false);
  const [showHideTimeLog, setShowHideTimeLog] = useState(false);
  const [invShowHide, setInvShowHide] = useState(false);

  const [handleDownloadFile] = useDocDownload();

  const years = getAllYears();
  const months = getAllMonths();

  const timeLogsSelector = useSelector(getTimeLogs);
  const timeLogInvoiceSelector = useSelector(getTimeLogInvoice);

  const usrType = localStorage.getItem("userType");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Candidate Invoice Detail"));
    dispatch(setCurrentRoute(route))
  }, [])

  useEffect(() => {
    const state = location.state;
    if (state) {
      setSelectedItem(state);
      let rate = 0;
      if (usrType === "recruiter" && state.rates) rate = state.rates.recruiterRate;
      if (usrType === "subcontractor" && state.rates) rate = state.rates.subcontractorRate;
      setRate(rate);
      setInvType(usrType);
      setPerformerData(state.performerData);
      dispatch(loadTimeLogs(state.performerid));
      dispatch(loadTimeLogInvoice(state.performerid));

      const entType = getFieldData(state.performerData, "candidateonboardingform", "CandidateEntity");
      setEntType(entType);
      const w9Type = getFieldData(state.performerData, "candidateonboardingform", "w9_Type");
      setW9Type(w9Type);
      const costBefenifit = getFieldData(state.performerData, "candidateonboardingform", "Cost_Of_Benefit");
      setCostBefenifit(costBefenifit);
    }
  }, []);

  useEffect(() => {
    setTimeLogs(timeLogsSelector);
  }, [timeLogsSelector])

  useEffect(() => {
    if (timeLogInvoiceSelector.length > 0) {
      const filterInvoices = timeLogInvoiceSelector.filter(x => x.invoiceType === invType);
      setTimeLogInvoices(filterInvoices);
    }
    else {
      setTimeLogInvoices([]);
    }
  }, [timeLogInvoiceSelector]);

  useEffect(() => {
    let filterTimeLogs = [...timeLogsSelector];
    if (filterYear > 0)
      filterTimeLogs = filterTimeLogs.filter(x => x.year === parseInt(filterYear));

    if (filterMonth > 0)
      filterTimeLogs = filterTimeLogs.filter(x => x.month === parseInt(filterMonth));

    setTimeLogs(filterTimeLogs);

  }, [filterMonth, filterYear])

  const getInvoiceAmount = (item) => {
    let invAmt = getTotalHours(item.workingHrs) * rate;
    if (entType !== "W2 Basis") return parseFloat(invAmt).toFixed(2);
    const taxRate = getFieldData(performerData, "candidateonboardingform", "Tax") / 100;
    invAmt += invAmt * taxRate;

    return parseFloat(invAmt).toFixed(2);
  };

  const getMonthlyPeriod = (obj) => {
    if (obj.workingHrs === null) return "";
    const wt = JSON.parse(obj.workingHrs);
    const sortWt = wt.sort((a, b) => new Date(a.start) - new Date(b.start));
    if (sortWt.length > 0) {
      return (
        <div>
          <Moment format="MMM Do YYYY">{sortWt[0].start}</Moment> -{" "}
          <Moment format="MMM Do YYYY">
            {sortWt[sortWt.length - 1].type === "m"
              ? getMonthLastDay(sortWt[sortWt.length - 1].start)
              : sortWt[sortWt.length - 1].start}
          </Moment>
        </div>
      );
    } else {
      return "";
    }
  };

  const getFinalAmout = (obj) => {
    let fAmt = parseFloat(getInvoiceAmount(obj));
    const invItems = timeLogInvoices.filter(
      (x) => x.timeLogId === obj.id
    );
    if (invItems.length > 0) {
      const item = invItems[0];
      if (item?.adjustmentMinues > 0)
        fAmt -= item.adjustmentMinues;
      if (item?.adjustmentPlus > 0)
        fAmt += item.adjustmentPlus;
      if (item?.adpDeduction > 0)
        fAmt -= item.adpDeduction;
      return parseFloat(fAmt).toFixed(2);
    } else {
      return parseFloat(fAmt).toFixed(2);
    }
  };

  const getInvoiceDeduction = (item) => {
    const tmLogs = timeLogInvoices.filter(
      (x) =>
        x.timeLogId === item.id
        && x.adpDeduction !== null
    );
    if (tmLogs.length === 0) {
      return "";
    }
    return <>
      {tmLogs.map((inv) => (
        <React.Fragment>
          {inv.adpDeduction}
          <br />
          {inv?.adpDocList?.map(
            (doc) => (
              <button
                className="btn btn-sm btn-link p-0"
                onClick={() =>
                  handleDownloadFile(
                    doc.invDoc
                  )
                }
              >
                {doc.documentName}
              </button>
            )
          )}
          {inv?.adpDocList?.length > 0 && <br />}
          <Moment format="MMM Do YYYY, h:mm a">
            {inv.adpDateTime}
          </Moment>
        </React.Fragment>
      ))}
    </>
  }

  const getGeneratedInvoice = (item) => {
    const tmLogs = timeLogInvoices.filter(
      (x) => x.timeLogId === item.id && x.invDoc !== null);
    if (tmLogs.length === 0) {
      return <button
        className="btn btn-sm btn-link p-0"
        onClick={() =>
          handleInvShowHide(item)
        }
      >
        Upload / Generate
      </button>
    }

    return <>
      {tmLogs.map((inv) => (
        <React.Fragment>
          {inv.invoiceNumber}
          <br />
          <button
            className="btn btn-sm btn-link p-0"
            onClick={() => handleDownloadFile(inv.invDoc)}
          >
            {inv.invDoc.documentName}
          </button>
        </React.Fragment>
      ))}
    </>
  }

  const getInvoiceStatus = (item) => {
    const tmLogs = timeLogInvoices.filter((x) =>
      x.timeLogId === item.id && x.status !== null);

    if (tmLogs.length > 0) {
      return <>
        {tmLogs.map((inv) => (
          <React.Fragment>
            {inv.status === true && (
              <span>
                Approved
              </span>
            )}
            {inv.status === false && (
              <span>
                Rejected
              </span>
            )}
            <br />
            <Moment format="MMM Do YYYY">
              {inv.reviewDateTime}
            </Moment>
          </React.Fragment>
        ))}
      </>
    }

    return "";
  }

  const getPaymentStatus = (item) => {
    const tmLogs = timeLogInvoices.filter(
      (x) => x.timeLogId === item.id &&
        x.paymentMode !== null
    );

    if (tmLogs.length > 0) {
      return <>
        {tmLogs.map(pay =>

          <React.Fragment>
            <button
              className="btn btn-sm btn-link p-0"
              onClick={() => handlePayShowHide(pay)}
            >
              Paid By IPCS
            </button>
            <br />

            <Moment format="MMM Do YYYY">
              {pay.payDateTime}
            </Moment>
            <br />
            Paid : {pay.paidAmount}
            {pay.docList?.length > 0 && <br />}
            {pay.docList?.map((doc) => (
              <button
                className="btn btn-sm btn-link p-0"
                onClick={() => handleDownloadFile(doc)}
              >
                {doc.documentName}
              </button>
            ))}

          </React.Fragment>
        )}
      </>
    }
    return "";
  }

  const getDueAmountStatus = (item) => {
    const tmLogs = timeLogInvoices
      .filter(
        (x) =>
          x.timeLogId === item.id &&
          x.paidAmount !== null
      );
    if (tmLogs.length === 0) return "";

    return <>
      {tmLogs.map((inv) => parseFloat(getFinalAmout(item) - inv.paidAmount).toFixed(2))}
    </>

  }

  const handlePayShowHide = (obj) => {
    setPayShowHide(!payShowHide);
    setTimeLogObj(obj);
  };

  const handleInvShowHide = (obj) => {
    if (obj === "save") {
      if (selectedItem?.performerid) {
        dispatch(loadTimeLogs(selectedItem.performerid));
        dispatch(loadTimeLogInvoice(selectedItem.performerid));
      }
      setInvShowHide(false);
      setTimeLogObj(null);
    }
    else {
      setInvShowHide(!invShowHide);
      setTimeLogObj(obj);
    }

  };

  const handleShowHide = (obj) => {
    setShowHide(!showHide);
    setTimeLogObj(obj);
  };

  const handleNotesComments = (type, obj) => {
    if (type === "save") {
      setTimeLogObj(null);
      setNoteShowHide(false);
    } else {
      setTimeLogObj(obj);
      setNoteShowHide(!noteShowHide);
    }
  };

  let item = "";
  if (selectedItem)
    item = selectedItem;

  return (
    <React.Fragment>

      <div className="col-md-12 text-right">
        <button
          className="btn btn-sm btn-outline-default btn-min-width mb-2 mt-2"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
        </button>
      </div>
      <div className="col-md-12 form-group">
        <div className="table-responsive">
          <table className="table align-items-center table-flush table-hover table-striped mb-2 border">
            <thead className="thead-light">
              <tr>
                <th>Candidate #<br />IPCS ID</th>
                <th>Candidate Details</th>
                <th>Agency / Client</th>
                <th>Duration</th>
                <th>Onboarding Status</th>
              </tr>
            </thead>
            <tbody>
              {item !== "" &&
                <tr>
                  <td>
                    {item.performerid}
                    <br />
                    {item.maxloans}
                  </td>
                  <td>
                    <span className="text-uppercase font-weight-bold">
                      {item.performername} {item.lastName}
                    </span>
                    <br />
                    <a href="#">{item.performeremail}</a>
                    <br />
                    {item.contactNo && getFormatedNumber(item.contactNo)}
                    <br />
                    {getFieldData(
                      item.performerData,
                      "projectengagement",
                      "PositionTitle"
                    )}
                  </td>
                  <td>
                    {getFieldData(
                      item.performerData,
                      "projectengagement",
                      "AgencyName"
                    )}
                  </td>
                  <td>
                    {item?.duration && (
                      <React.Fragment>
                        {item.duration.duration} Months
                        <br />
                        Start :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.startDate}
                        </Moment>
                        <br />
                        End :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.endDate}
                        </Moment>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {item.appSubmit && (
                      <span>
                        Complete
                      </span>
                    )}
                    {!item.appSubmit && (
                      <span>
                        Pending
                      </span>
                    )}
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <i className="fa fa-square wf-phase6-box mr-2" aria-hidden="true"></i>
            <span className="h3">
              Invoice Detail
            </span>

          </div>
          <div className="card-body p-3">
            <div className="row">
              <div className="col-md-6 form-group">
                <label
                  htmlFor="filterYear"
                  className="form-control-label"
                >
                  Invoice Year
                </label>
                <select
                  name="filterYear"
                  id="year"
                  className="form-control"
                  value={filterYear}
                  onChange={({ currentTarget }) => setFilterYear(currentTarget.value)}
                >
                  <option value="">Year</option>
                  {years.map((y) => (
                    <option value={y.name}>{y.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="filterMonth"
                  className="form-control-label"
                >
                  Invoice Month
                </label>
                <select
                  name="filterMonth"
                  id="month"
                  className="form-control"
                  value={filterMonth}
                  onChange={({ currentTarget }) => setFilterMonth(currentTarget.value)}
                >
                  <option value="">Month</option>
                  {months.map((m) => (
                    <option value={m.id}>{m.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table align-items-center table-flush table-striped mb-2 border">
                    <thead className="thead-light">
                      <tr>
                        <th>Year</th>
                        <th>Month</th>
                        <th>Period</th>
                        <th>Time Logged</th>
                        <th>Hrs Worked<br />Days Worked</th>
                        <th>Rate/Hr ($)</th>
                        <th>Invoice<br />Amount ($)
                        </th>
                        {w9Type === "W2 Hourly with Benefits" && (
                          <th>Cost of benefit ($)</th>
                        )}
                        {entType === "W2 Basis" && usrType !== "recruiter" && (
                          <th>ADP Deduction ($)</th>
                        )}
                        <th>Invoice</th>
                        <th>IPCS Review</th>
                        <th>Final Pay<br />Amount ($)</th>
                        <th>Payment<br />Amount ($)</th>
                        <th>Due Amount ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {timeLogs.map((item) => (
                        <tr>
                          <td>{item.year}</td>
                          <td>{getMonthName(item.month)}</td>
                          <td>{getMonthlyPeriod(item)}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-link p-0"
                              onClick={() => handleShowHide(item)}
                            >
                              View
                            </button>
                            <br />
                            {item.docList?.map((doc) => (
                              <li className="list-group-item p-0">
                                <button
                                  className="btn btn-sm btn-link"
                                  onClick={() => handleDownloadFile(doc)}
                                >
                                  {doc.documentName}
                                </button>

                              </li>
                            ))}
                          </td>

                          <td>
                            {getTotalHours(item.workingHrs)}
                            <br />
                            {getTotalDays(item.workingHrs) + " Days"}
                          </td>
                          <td>{rate}</td>
                          <td>
                            {getInvoiceAmount(item)}
                          </td>
                          {w9Type === "W2 Hourly with Benefits" && (
                            <td>{costBefenifit}</td>
                          )}
                          {entType === "W2 Basis" && usrType !== "recruiter" && (
                            <td>
                              {getInvoiceDeduction(item)}
                            </td>
                          )}
                          {(entType === "C2C Basis" || usrType === "recruiter") && (
                            <td>
                              {getGeneratedInvoice(item)}
                            </td>
                          )}

                          <td>
                            <button
                              className="btn btn-sm btn-link p-0"
                              onClick={() => handleNotesComments("", item)}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            {getFinalAmout(item)}
                            <br />
                            {getInvoiceStatus(item)}
                          </td>
                          <td>
                            {getPaymentStatus(item)}
                          </td>
                          <td>
                            {getDueAmountStatus(item)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CandidatePay
        payShowHide={payShowHide}
        handlePayShowHide={handlePayShowHide}
        candidateObj={item}
        selectedObj={timeLogObj}
      ></CandidatePay>

      <AddNewTimeLog
        showHide={showHide}
        handleShowHide={handleShowHide}
        selectedObj={timeLogObj}
        candidateObj={item}
        viewType="invoice"
        allTimeLogs={timeLogs}
      ></AddNewTimeLog>

      <UploadInvoice
        invShowHide={invShowHide}
        handleInvShowHide={handleInvShowHide}
        candidateObj={item}
        selectedObj={timeLogObj}
      ></UploadInvoice>

      <AddEditNotes
        noteShowHide={noteShowHide}
        handleNoteShowHide={handleNotesComments}
        notesType={notesType}
        candidateObj={item}
        timeLogObj={timeLogObj}
      ></AddEditNotes>
    </React.Fragment>
  );
}
