import React from "react";
import Other_textbox from "../../../BprmControls/Other_textbox";
import Phone_textbox from "../../../BprmControls/Phone_textbox";
import SSN_textbox from "../../../BprmControls/SSN_textbox";
import Joi from "joi-browser";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { formatPhoneNumber, formatSSNNumber } from "../../../Services/appService";

export default function TextBox(props) {

    const { controleId, 
        comp, 
        stateData: data, 
        stateErrors: errors,
        stateSchema:schema,
        codeIsVerified,
        isDisabled,
        viewTypeType,
        handleChange,
        handeOnChange,
        getEvaluatedValue
    } = props;
    const getPopover = (notes) => {
        const popover = (
            <Popover id="popover-basic" style={{ minWidth: "500px" }}>
                <Popover.Title as="h4">Rule Expression</Popover.Title>
                <Popover.Content>{notes}</Popover.Content>
            </Popover>
        );
        return (
            <React.Fragment>
                <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                    <button className="btn btn-sm btn-outline-success ml-2">
                        <i className="fa fa-eye mr-2"></i>View Rule
                    </button>
                </OverlayTrigger>
            </React.Fragment>
        );
    };
    // console.log(viewTypeType, "za")
    const handleEncryptDecryptedOnChange = (name, valueArray) => {
        let decValue = "";
        for (let index = 0; index < valueArray.length; index++) {
            const element = valueArray[index];
            decValue += element;
        }
        const input = { name: name, value: decValue };
        handleChange(input);
    };

    const handeSsnPhoneOnChange = ({ currentTarget: input }) => {
        input.value = input.value.replace(/[^\d]/g, "");
        handleChange(input);
    };    

    let inputType = "";
    if (comp?.attributeDataTypes?.type)
        inputType = comp.attributeDataTypes.type;
    
    if (inputType === "phone" || inputType === "mobile") {
        schema[controleId] = !comp.isRequired
            ? Joi.string()
                .length(10)
                .options({
                    language: {
                        string: {
                            length: comp.label + " must be 10 digits.",
                        },
                    },
                })
                .label(comp.label)
            : Joi.string()
                .length(10)
                .options({
                    language: {
                        string: {
                            length: comp.label + " must be 10 digits.",
                        },
                    },
                })
                .required()
                .label(comp.label);
    }

    if (inputType === "ssn") {
        schema[controleId] = !comp.isRequired
            ? Joi.string()
                .length(9)
                .options({
                    language: {
                        string: {
                            length: comp.label + " must be 9 digits.",
                        },
                    },
                })
                .label(comp.label)
            : Joi.string()
                .length(9)
                .options({
                    language: {
                        string: {
                            length: comp.label + " must be 9 digits.",
                        },
                    },
                })
                .required()
                .label(comp.label);
    }

    if (inputType === "email") {
        schema[controleId] = !comp.isRequired
            ? Joi.string().email().label(comp.label)
            : Joi.string().email().required().label(comp.label);
    }

    return (
        <div className={`form-group ${comp.isRequired ? "required" : ""}`}>
            <label className="form-control-label" htmlFor={controleId}>
                {comp.label}{" "}
                {comp?.rule && getPopover(comp.ruleExpression)}
            </label>
            {comp?.encryptionValidate ? (
                <>
                    {inputType === "ssn" && (
                        <SSN_textbox
                            value={data[controleId]}
                            label={comp.label}
                            isDisabled={isDisabled}
                            name={controleId}
                            handleOnChange={handleEncryptDecryptedOnChange}
                            codeIsVerified={codeIsVerified}
                        />
                    )}
                    {(inputType === "phone" || inputType === "mobile") && (
                        <Phone_textbox
                            value={data[controleId]}
                            label={comp.label}
                            isDisabled={isDisabled}
                            name={controleId}
                            handleOnChange={handleEncryptDecryptedOnChange}
                            codeIsVerified={codeIsVerified}
                        />
                    )}
                    {(inputType === "" || inputType === "email") && (
                        <Other_textbox
                            value={data[controleId]}
                            label={comp.label}
                            isDisabled={isDisabled}
                            name={controleId}
                            handleOnChange={handleEncryptDecryptedOnChange}
                            codeIsVerified={codeIsVerified}
                        />
                    )}
                </>
            ) : (
                <div className="d-flex">
                    {inputType === "ssn" && (
                        <input
                            type="text"
                            className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                            placeholder={comp.label}
                            disabled={isDisabled}
                            name={controleId}
                            value={formatSSNNumber(data[controleId])}
                            onChange={handeSsnPhoneOnChange}
                            title="SSN 9 Digit"
                            aria-label={comp.label}
                            aria-required={comp.isRequired}
                        ></input>
                    )}

                    {(inputType === "phone" || inputType === "mobile") && (
                        <input
                            type="text"
                            className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                            placeholder={comp.label}
                            disabled={isDisabled}
                            name={controleId}
                            value={formatPhoneNumber(data[controleId])}
                            onChange={handeSsnPhoneOnChange}
                            title={comp.label}
                            aria-label={comp.label}
                            aria-required={comp.isRequired}
                        ></input>
                    )}

                    {(inputType === "" || inputType === "email") && (
                        <React.Fragment>
                            {/* {console.log(viewTypeType, controleId, "ioi")} */}
                            <input
                                type="text"
                                className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                                id={controleId}
                                placeholder={comp.label}
                                name={controleId}
                                value={!data[controleId] ? "" : data[controleId]}
                                onChange={handeOnChange}
                                // disabled={isDisabled}
                                disabled={viewTypeType === "Edited" && controleId === "candidateonboardingform.IPCS_Existing_ID" ? true : isDisabled}
                                aria-required={comp.isRequired}
                                readOnly={comp?.rule}
                            />

                            {comp?.rule && (
                                <button
                                    className="btn btn-sm btn-primary ml-2"
                                    onClick={() => getEvaluatedValue(comp)}
                                >
                                    Apply Rule
                                </button>
                            )}
                        </React.Fragment>
                    )}
                </div>
            )}
            {errors[controleId] && (
                <div className="alert alert-danger">{errors[controleId]}</div>
            )}
        </div>
    )
}