import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import PageHeader from "../Comman/PageHeader";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import ChecklistItem from "../Comman/ChecklistItem";
import UploadDocPopup from "../UploadDocPopup";
import CandidateInfo from "../../app/components/CandidateInfo";
import { useDispatch, useSelector } from "react-redux";
import { getAppDocuments, getAppTasks, getCandidateApps, loadAppDocuments, loadAppRuningTasks, loadCandidateApps } from "../../app/store/candidate";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { getAlert } from "../../Services/appAlerts";
import PartnerInfo from "../../app/components/PartnerInfo";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import useDocDownload from "../../app/hooks/useDocDownload";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../app/store/tools";
import { getInvDocuments, getReqDocuments, loadInvoiceDocuments, loadReqDocuments } from "../../app/store/documents";

export default function SentDocs() {

  const route = [{ url: "", name: "Task Management" }];
  const route1 = [{ url: "", name: "Contract Management" }];

  const usrType = localStorage.getItem("userType");

  const [appTasklist, setAppTasklist] = useState([]);
  const [sentDocs, setSentDocs] = useState([]);
  const [receivedDocs, setReceivedDocs] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [uploadDocShowHide, setUploadDocShowHide] = useState(false);
  const [wfTask, setWfTask] = useState([]);
  const [wfId, setWfId] = useState(0);

  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const candidateAppsSelector = useSelector(getCandidateApps);
  const appTasksSelector = useSelector(getAppTasks);
  const appDocumentsSelector = useSelector(getAppDocuments);
  const reqDocumentsSelector = useSelector(getReqDocuments);
  const invDocumentsSelector = useSelector(getInvDocuments);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);

  const [handleDownloadFile] = useDocDownload();

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Document History"));
    dispatch(setCurrentRoute(usrType === "candidate" ? route : route1))
  }, [])

  useEffect(() => {
    if (!userDetailsSelector)
      dispatch(loadUserDetails(currentUser.id));
    dispatch(loadCandidateApps(currentUser.id));
  }, []);

  useEffect(() => {
    setUserDetails(userDetailsSelector);
  }, [userDetailsSelector])

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        setWfId(wf.oldWorkflowId);
        dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
        dispatch(loadAppDocuments(wf.oldWorkflowId, currentUser.id));
        dispatch(loadInvoiceDocuments(currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
      setAppTasklist(filterTasks);
      if (filterTasks.length > 0) {
        dispatch(loadReqDocuments(filterTasks[0].ciid));
      }
    }
  }, [appTasksSelector]);

  useEffect(() => {
    if (appDocumentsSelector.length > 0) {
      const appDocs = [...appDocumentsSelector];
      console.log(appDocs)
      let receiveDoc = appDocs.filter(
        (x) =>
          x.type === "received"
          && x.uploadType !== "Invoice"
          // && x.uploadType !== "reporting" 
          // && x.uploadType !== "client" 
          // && x.uploadType !== "project" 
          // && x.docType !== "internal" 
          && x.sendStatus === true
        // && x.deleteFlag !== true
      );
      let msgDocs = appDocs.filter(x => x.type === "received" && x.docType === "Messaging");
      receiveDoc = [...receiveDoc, ...msgDocs];
      let sentDocs = appDocs.filter(
        (x) =>
          x.type === "send"
          && x.uploadType !== "monthly_time_sheet"
          && x.uploadType !== "Invoice"
        // && x.docType !== "internal" 
        // && x.uploadType?.substring(0, 3) !== "ach" 
        // && x.uploadType?.substring(0, 9) !== "insurance"
      );

      if (reqDocumentsSelector.length > 0) {
        // reqDocumentsSelector.map(r => {
        //   sentDocs = sentDocs.filter(x => x.uploadType !== "req_" + r.id);
        // })
      }

      if (userDetails) {
        const subId = userDetails.subcontractor?.fuid;
        // receiveDoc = receiveDoc.filter(
        //   (x) =>
        //     x.uploadType !== `1_c2c_${subId}` &&
        //     x.uploadType !== `2_c2c_${subId}` &&
        //     x.uploadType !== `3_c2c_${subId}` &&
        //     x.uploadType !== `4_c2c_${subId}`
        // );
      }
      //console.log(receiveDoc)
      setReceivedDocs(receiveDoc);
      setSentDocs(sentDocs);

      let docItems = "";
      receiveDoc.map((x, key) => {
        if (key > 0) docItems += ",";
        docItems += x.id;
      })

      sentDocs.map((x, key) => {
        if (key > 0) docItems += ",";
        docItems += x.id;
      })
      let appCiid = 0;
      if (receiveDoc.length > 0)
        appCiid = receiveDoc[0].ciid;

      if (sentDocs.length > 0)
        appCiid = sentDocs[0].ciid;

      handleUpdateComponent(docItems, appCiid);

    }
  }, [reqDocumentsSelector, invDocumentsSelector])


  const handleUploadDocModalShowHide = (docs) => {
    if (docs === "save") {
      setUploadDocShowHide(false);
      dispatch(loadAppDocuments(wfId, currentUser.id));
    }
    else {
      const reviewApp = appTasklist.filter((x) => x.registrationType === "WP");
      if (reviewApp.length > 0) {
        const lastReviewer = reviewApp[0];
        setWfTask([lastReviewer]);
      } else {
        getAlert("", "No reviewer found", "error");
      }
      setUploadDocShowHide(!uploadDocShowHide);
    }
  };

  const handleUpdateComponent = (compItems, ciid) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === 2);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: "Document",
      performerId: currentUser.id,
      compItems: compItems,
      ciid: ciid,
      wiid: 0,
      iiid: 0,
      compId: 2
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  const getDocType = (type) => {
    if (type === "sharing")
      return "Contract";
    else if (type?.substring(0, 3) === "ach")
      return "ACH Void Check";
    else if (type?.substring(0, 9) === "insurance")
      return "COI Insurance";
    else if (type?.substring(0, 3) === "req" || type?.includes("_c2c_"))
      return "Contract";
    else return type;
  }

  return (
    <React.Fragment>

      <div className="col-md-12 mt-2">
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <div className="row">
              <div className="col-md-6">
                <i className="fa fa-square wf-phase-box mr-2" aria-hidden="true"></i>
                <span className="h3">
                  Document History
                </span>
                <p className="text-red">
                  Below is the history of documents received and sent.
                </p>
              </div>
              <div className="col-md-6 text-right">
                {/* <button
                  className="btn btn-sm btn-outline-default mt-2"
                  onClick={() => handleUploadDocModalShowHide("")}
                >
                  Upload Document
                </button> */}
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped border">
                <thead className="thead-light">
                  <tr>
                    <th colSpan={5} className="bg-white border border-bottom-0">Sent Documents</th>
                  </tr>
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time Sent</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {sentDocs.map((item, key) => (
                    <tr>
                      <td>{key + 1}
                      </td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.createdDateTime}
                        </Moment>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-link pl-0"
                          title="Download Document"
                          onClick={() => handleDownloadFile(item)}
                        >
                          {item.documentName}
                        </button>

                      </td>
                      <td>
                        <p>{getDocType(item.uploadType)} {item.docModifiedName}</p>
                      </td>
                      <td>{item.docSize}</td>
                      <td>{item.notes}</td>
                    </tr>
                  ))}
                </tbody>
                <thead className="thead-light">
                  <tr>
                    <th colSpan={6} className="bg-white border border-bottom-0">Received Documents</th>
                  </tr>
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time Received</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {receivedDocs.map((item, key) => (
                    <tr>
                      <td>{key + 1} </td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.createdDateTime}
                        </Moment>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-link pl-0"
                          title="Download Document"
                          onClick={() => handleDownloadFile(item)}
                        >
                          {item.documentName}
                        </button>

                      </td>
                      <td>
                        <p>{getDocType(item.docType)} {item.docModifiedName}</p>

                      </td>
                      <td>{item.docSize}</td>
                      <td>{item.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      {uploadDocShowHide &&

        <UploadDocPopup
          uploadDocShowHide={uploadDocShowHide}
          handleUploadDocModalShowHide={handleUploadDocModalShowHide}
          runningApplication={wfTask}
        ></UploadDocPopup>}
    </React.Fragment>
  );
}
