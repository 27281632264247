import React, { Component, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "../Comman/Form";
import http from "../../Services/httpService";
import config from "../../config.json";
import auth from "../../Services/authService";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddWorkingHrs from "./AddWorkingHrs";
import { getAllMonths, getAllYears, getFieldData } from "../../Services/appService";
import { getCurrentUser } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { addNewTmLog, getTmLogAddedStatus } from "../../app/store/timeLog";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps } from "../../app/store/candidate";
import { appDocumentUploaded, getAppDocUploadStatus, uploadAppDocuments } from "../../app/store/documents";
import { getAlert, getConfirmAlert } from "../../Services/appAlerts";

const AddNewTimeLog = (props) => {

  const { showHide: tmShowHide,
    handleShowHide, viewType, selectedObj,
    allTimeLogs, candidateObj, timeLogInvoices } = props;

  const localizer = momentLocalizer(moment);

  const years = getAllYears();
  const months = getAllMonths();

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [docType, setDocType] = useState("monthly");
  const [monthlyHrs, setMonthlyHrs] = useState("");
  const [chkMonthlyHrs, setChkMonthlyHrs] = useState("");
  const [monthlyDays, setMonthlyDays] = useState("");
  const [documents, setDocuments] = useState([]);
  const [wrkHrShowHide, setWrkHrShowHide] = useState(false);
  const [dtObj, setDtObj] = useState(null);
  const [currentWfTask, setCurrentWfTask] = useState(null);
  const [saveTmWithDocs, setSaveTmWithDocs] = useState(false);
  const [saveBtnProccess, setSaveBtnProccess] = useState(false);

  const candidateAppsSelector = useSelector(getCandidateApps);
  const appTasksSelector = useSelector(getAppTasks);
  const appDocUploadStatusSelector = useSelector(getAppDocUploadStatus);
  const tmLogAddedStatusSelector = useSelector(getTmLogAddedStatus);

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (candidateAppsSelector.length === 0)
      dispatch(loadCandidateApps(currentUser.id))
  }, []);

  useEffect(() => {
    if (!tmShowHide) {
      //setAllEvents([]);
      setDocuments([])
    }
  }, [tmShowHide])

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        if (appTasksSelector.length === 0)
          dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      const filterTasks = appTasksSelector.filter(x => x.wiid > 0 && x.wfPhase === "Approved");
      let firstAppTasks = [];
      if (filterTasks.length > 0) {
        firstAppTasks = filterTasks[filterTasks.length - 1]
        setCurrentWfTask(firstAppTasks)
      }
    }
  }, [appTasksSelector]);

  useEffect(() => {
    if (allTimeLogs.length > 0) {
      const events = [];
      allTimeLogs.map((t) => {
        if (t.workingHrs && t.workingHrs !== "") {
          const wt = JSON.parse(t.workingHrs);
          wt.map((w) => {
            const ev = { start: w.start, end: w.start, title: w.title };
            events.push(ev);
          });
        }
      });
      setAllEvents(events);
      setDocuments([]);
    }
  }, [allTimeLogs, tmShowHide])

  useEffect(() => {
    if (selectedObj) {
      setSelectedYear(selectedObj.year);
      setSelectedMonth(selectedObj.month);
      setDocType(selectedObj.docUploadType);
      setDocuments([]);
      const dt = new Date(selectedObj.year, selectedObj.month - 1, 1);
      setSelectedDate(dt);
    }
    else {
      const dt = new Date();
      const year = dt.getFullYear();
      const month = dt.getMonth();
      setSelectedYear(year);
      setSelectedMonth(month + 1);
      setSelectedDate(dt);
      setChkMonthlyHrs("");
      setDocType("monthly");
      setMonthlyHrs("");
      setMonthlyDays("");
      setDocuments([]);
    }
  }, [selectedObj]);

  const handleOnChange = ({ currentTarget }) => {
    const id = currentTarget.id.split("_")[1];
    const name = currentTarget.id.split("_")[0];
    const { timeLogs } = this.state;
    let obj = timeLogs.filter((x) => x.id === parseInt(id))[0];
    const index = timeLogs.findIndex((x) => x.id === parseInt(id));
    obj[name] = currentTarget.value;
    timeLogs[index] = obj;
    //this.setState({ timeLogs });
  };

  const doSubmit = (type) => {
    if (type === "reset") setSelectedYear(null);
    const selectedTimeLog = allTimeLogs.filter((x) =>
      x.year === parseInt(selectedYear) && x.month === parseInt(selectedMonth)
    );
    if (selectedTimeLog.length > 0)
      checkInvoiceStatus(selectedTimeLog);
    else
      saveTmLogs();
  };

  const checkInvoiceStatus = (selectedTimeLog) => {
    const tmLog = selectedTimeLog[0];
    const invType = "subcontractor";
    const checkApproved = timeLogInvoices.filter((x) =>
      x.timeLogId === tmLog.id &&
      x.invoiceType === invType &&
      x.status === true
    );
    if (checkApproved.length > 0)
      getAlert("", "Invoice already approved", "error");
    else
      saveTmLogs();
  }

  const saveTmLogs = async () => {
    const result = await getConfirmAlert("Warning", "Are you sure you have entered the correct hours for the week/month?", "warning");
    if (result.isConfirmed) {
      setSaveBtnProccess(true);
      if (documents.length > 0) saveTmDocs();
      else saveTimeLogs(0);
    }
  }

  const saveTmDocs = () => {
    const frmData = new FormData();
    frmData.append("message", "Time Sheet");
    frmData.append("workflowTask", JSON.stringify(currentWfTask));
    frmData.append("userId", currentUser.id);
    frmData.append("docType", docType + "_time_sheet");
    documents.map((file, key) =>
      frmData.append("files[" + key + "]", file.file)
    );
    dispatch(uploadAppDocuments(frmData));
    setSaveTmWithDocs(true);
  }

  const saveTimeLogs = (docId) => {
    const filterEvents = allEvents.filter((x) => {
      const dt = new Date(x.start);
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      if (parseInt(selectedYear) == year && parseInt(selectedMonth) == month) {
        return x;
      }
    });
    const tmObj = {};
    if (chkMonthlyHrs === "monthly" && parseFloat(monthlyHrs) > 0) {
      let selMonth = selectedMonth;
      let selDays = monthlyDays;
      if (selectedMonth < 10) selMonth = "0" + selMonth;
      if (selDays === "" || !selDays) selDays = 0;
      const newEvents = [{
        start: `${selectedYear}-${selMonth}-01T05:00:00.000Z`,
        end: `${selectedYear}-${selMonth}-02T05:00:00.000Z`,
        title: monthlyHrs,
        type: "m",
        days: selDays,
      }];
      tmObj["workingHrs"] = JSON.stringify(newEvents);
    } else {
      tmObj["workingHrs"] = JSON.stringify(filterEvents);
      if (filterEvents.length === 0) tmObj["workingHrs"] = null;
    }
    tmObj["candidateId"] = candidateObj.performerid;
    tmObj["subcontractorId"] = candidateObj.validId;
    tmObj["docId"] = docId;
    tmObj["docUploadType"] = docType;
    tmObj["year"] = selectedYear;
    tmObj["month"] = selectedMonth;

    if (selectedObj?.id > 0)
      tmObj["id"] = selectedObj.id;

    // const entType = getFieldData(candidateObj.performerData, "candidateonboardingform", "CandidateEntity");
    // if (entType === "C2C Basis") {
    //   tmObj["rate"] = getFieldData(candidateObj.performerData, "candidateonboardingform", "Rate");
    // } else {
    //   tmObj["rate"] = getFieldData(candidateObj.performerData, "candidateonboardingform", "Rate_to_Candidate");
    // }
    dispatch(addNewTmLog(tmObj));
  };

  useEffect(() => {
    if (tmLogAddedStatusSelector) {
      if (selectedObj?.id > 0)
        getAlert("", "Updated Successfully", "success");
      else
        getAlert("", "Added Successfully", "success");

      setSaveBtnProccess(false);
      handleShowHide("save");
    }
  }, [tmLogAddedStatusSelector])

  useEffect(() => {
    if (appDocUploadStatusSelector > 0 && saveTmWithDocs) {
      saveTimeLogs(appDocUploadStatusSelector);
      setSaveTmWithDocs(false);
      dispatch(appDocumentUploaded(null));
    }
  }, [appDocUploadStatusSelector]);

  const handleSelect = (wrkHr) => {
    if (dtObj) {
      const { start, end } = dtObj;
      const dt = new Date(start);
      const chk = allEvents.filter((x) => {
        const evDt = new Date(x.start);
        if (dt.getFullYear() === evDt.getFullYear() &&
          dt.getMonth() === evDt.getMonth() &&
          dt.getDate() === evDt.getDate()) {
          return x;
        }
      });
      if (chk.length > 0) {
        allEvents.filter((x) => {
          const evDt = new Date(x.start);
          if (dt.getFullYear() === evDt.getFullYear() &&
            dt.getMonth() === evDt.getMonth() &&
            dt.getDate() !== evDt.getDate()) {
            return x;
          }
        });

        const obj = chk[0];
        obj.title = wrkHr;
        const events = [...allEvents];
        setAllEvents(events);
        setWrkHrShowHide(false);
        setDtObj(null);
      } else {
        const obj = { start, end, title: wrkHr }
        const events = [...allEvents, obj];
        setAllEvents(events);
        setWrkHrShowHide(false);
        setDtObj(null);
      }
    }
  };

  const handleeEditSelect = (wrkHr) => {
    if (dtObj) {
      const events = [...allEvents];
      const { start, end, title } = dtObj;
      const index = events.findIndex(
        (x) => x.start === start && x.end === end && x.title === title
      );
      const obj = events[index];
      obj.title = wrkHr;
      events[index] = obj;
      setAllEvents(events);
    }
  };

  const handleYearChange = ({ currentTarget }) => {
    setSelectedYear(currentTarget.value);
    const newDt = new Date(currentTarget.value, selectedMonth - 1, 1);
    setSelectedDate(newDt);
  };

  const handleMonthChange = ({ currentTarget }) => {
    setSelectedMonth(currentTarget.value);
    const newDt = new Date(selectedYear, currentTarget.value - 1, 1);
    setSelectedDate(newDt);
  };

  const handleDateChanged = (date) => {
    const dt = new Date(date);
    const year = dt.getFullYear();
    const month = dt.getMonth();
    const cyear = new Date().getFullYear();
    const cmonth = new Date().getMonth();

    const findYear = years.filter(x => x.name === year);

    if (year === cyear && month <= cmonth && findYear.length > 0) {
      setSelectedYear(year);
      setSelectedMonth(month + 1);
      setSelectedDate(dt);
    }
    else if (year < cyear && findYear.length > 0) {
      setSelectedYear(year);
      setSelectedMonth(month + 1);
      setSelectedDate(dt);
    }
  };


  const onFileChange = async (event) => {
    const docs = [...documents];
    for (let file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  const handleWrkHrShowHide = (obj) => {
    const dt = new Date(obj.start);
    const cdt = new Date();
    if (dt <= cdt && parseInt(selectedMonth) === dt.getMonth() + 1) {
      setWrkHrShowHide(!wrkHrShowHide);
      setDtObj(obj);
    } else if (obj === "") {
      setWrkHrShowHide(false);
      setDtObj(null);
    }
  };

  const handleChkMonthly = ({ target }) => {
    if (target.checked) {
      setChkMonthlyHrs("monthly");
    } else {
      setChkMonthlyHrs("");
    }
  };

  const handleReset = async () => {
    const result = await getConfirmAlert("Warning", "Are you sure you want to Reset the entered hours for the week(s)/month?", "warning");
    if (result.isConfirmed) {
      if (selectedObj?.id > 0) {
        setSelectedYear(selectedObj.year);
        setSelectedMonth(selectedObj.month);
        setDocType(selectedObj.docUploadType);
        setDocuments([]);
        const dt = new Date(selectedObj.year, selectedObj.month - 1, 1);
        setSelectedDate(dt);
      }
      else {
        const dt = new Date();
        const year = dt.getFullYear();
        const month = dt.getMonth();
        setSelectedYear(year);
        setSelectedMonth(month + 1);
        setSelectedDate(dt);
        setChkMonthlyHrs("");
        setDocType("monthly");
        setMonthlyHrs("");
        setMonthlyDays("");
        setDocuments([]);
      }
      const events = [];
      allTimeLogs.map((t) => {
        if (t.workingHrs !== null && t.workingHrs !== "") {
          const wt = JSON.parse(t.workingHrs);
          wt.map((w) => {
            const ev = { start: w.start, end: w.start, title: w.title };
            events.push(ev);
          });
        }
      });
      setAllEvents(events);
      getAlert("", "Reset Successfully", "success");
    }
  };

  let filterMonths = months.filter(x => x.id <= (new Date().getMonth() + 1));
  if (selectedYear < (new Date().getFullYear()))
    filterMonths = months;

  return (
    <>
      <Modal show={tmShowHide} size="xl">
        <Modal.Header closeButton onHide={() => handleShowHide(null)}>
          <Modal.Title>Add/Edit Time Log</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-6 form-group">

              <select
                name="year"
                id="year"
                className="form-control"
                onChange={handleYearChange}
                value={selectedYear}
              >
                <option value="">Select Year</option>
                {years.filter(x => x.name <= (new Date).getFullYear()).map((y) => (
                  <option value={y.name}>{y.name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6 form-group">

              <select
                name="month"
                id="month"
                className="form-control"
                onChange={handleMonthChange}
                value={selectedMonth}
              >
                <option value="">Select Month</option>
                {filterMonths.map((m) => (
                  <option value={m.id}>{m.name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-12 form-inline">
              <div className="custom-control custom-radio mb-3">
                <input
                  name="docType"
                  className="custom-control-input"
                  id="timeSheetWeekly"
                  type="radio"
                  onChange={() => setDocType("weekly")}
                  value="weekly"
                  checked={docType === "weekly"}
                />
                <label
                  className="custom-control-label mr-2"
                  htmlFor="timeSheetWeekly"
                >
                  Upload weekly time sheets
                </label>
              </div>
              <div className="custom-control custom-radio mb-3">
                <input
                  name="docType"
                  className="custom-control-input"
                  id="timeSheetMonthly"
                  type="radio"
                  onChange={() => setDocType("monthly")}
                  value="monthly"
                  checked={docType === "monthly"}
                />
                <label
                  className="custom-control-label"
                  htmlFor="timeSheetMonthly"
                >
                  Upload monthly time sheet
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-control custom-checkbox mb-3">
                <input
                  name="chkMonthlyHrs"
                  className="custom-control-input"
                  id="chkMonthlyHrs"
                  type="checkbox"
                  onChange={handleChkMonthly}
                  value="monHrs"
                />
                <label
                  className="custom-control-label mr-2"
                  htmlFor="chkMonthlyHrs"
                >
                  Check Monthly Hours
                </label>
              </div>
            </div>
            {chkMonthlyHrs === "monthly" && <>
              <div className="col-md-6 form-group">
                <label htmlFor="monthlyHrs" className="form-control-label">
                  Enter Monthly Hours
                </label>
                <input type="number"
                  className="form-control"
                  id="monthlyHrs"
                  name="monthlyHrs"
                  value={monthlyHrs}
                  placeholder="Enter Monthly Hours"
                  onChange={({ currentTarget }) => setMonthlyHrs(currentTarget.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="monthlyDays" className="form-control-label">
                  Enter Total Days
                </label>
                <input type="number"
                  className="form-control"
                  id="monthlyDays"
                  name="monthlyDays"
                  value={monthlyDays}
                  placeholder="Enter Total Days"
                  onChange={({ currentTarget }) => setMonthlyHrs(currentTarget.value)}
                />

              </div> </>}
            <div className="col-md-12" style={{ height: "350px" }}>
              <Calendar
                selectable
                localizer={localizer}
                events={allEvents}
                date={selectedDate}
                views={["month"]} // "week", "work_week"
                onSelectEvent={handleWrkHrShowHide}
                onSelectSlot={handleWrkHrShowHide}
                onNavigate={handleDateChanged}

              //onView={(view) => setState({ selectedView: view })}
              />
            </div>
            {docType === "weekly" ?
              <div className="col-md-12 mt-2">
                <table className="table table-bordered">
                  <thead className="bg-lighter">
                    <tr>
                      <th>
                        <b>Week</b>
                      </th>
                      <th>
                        <b>Upload weekly approved time sheets</b>
                      </th>
                      <th>
                        <b>Week</b>
                      </th>
                      <th>
                        <b>Upload weekly approved time sheets</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <input
                          type="file"
                          id="week1"
                          name="week1"
                          className="form-control"
                          onChange={onFileChange}
                        />
                      </td>
                      <td>2</td>
                      <td>
                        <input
                          type="file"
                          id="week2"
                          name="week2"
                          className="form-control"
                          onChange={onFileChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <input
                          type="file"
                          id="week3"
                          name="week3"
                          className="form-control"
                          onChange={onFileChange}
                        />
                      </td>
                      <td>4</td>
                      <td>
                        <input
                          type="file"
                          id="week4"
                          name="week4"
                          className="form-control"
                          onChange={onFileChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> : <>
                <div className="col-md-6 mt-2">
                  <label htmlFor="timeSheet" className="form-control-label">
                    Upload monthly approved time sheet
                  </label>
                  <input
                    type="file"
                    name="timeSheet"
                    id="timeSheet"
                    className="form-control"
                    onChange={onFileChange}
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <label htmlFor="signDoc" className="form-control-label">
                    Upload monthly supporting document (If Required)
                  </label>
                  <input
                    type="file"
                    name="signDoc"
                    id="signDoc"
                    className="form-control"
                    onChange={onFileChange}
                  />
                </div> </>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleShowHide(null)}>
            <i class="fa fa-times" aria-hidden="true"></i> Close
          </button>
          {viewType !== "invoice" && (
            <>
              <button className="btn btn-sm btn-outline-default"
                onClick={handleReset}>
                <i class="fa fa-refresh" aria-hidden="true"></i> Reset
              </button>
              <button
                className="btn btn-sm btn-outline-default"
                disabled={saveBtnProccess}
                onClick={() => doSubmit("save")}
              >
                {saveBtnProccess ? <i className="fa fa-spinner fa-spin"></i> :
                  <i class="fa fa-check" aria-hidden="true"></i>} Save
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <AddWorkingHrs
        handleShowHide={handleWrkHrShowHide}
        showHide={wrkHrShowHide}
        selectedObj={dtObj}
        handleSelect={handleSelect}
        handleeEditSelect={handleeEditSelect}
        allEvents={allEvents}
      ></AddWorkingHrs>
    </>
  );
}

export default AddNewTimeLog;
