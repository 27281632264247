import React from "react";

const Radio = (props) => {

  const {
    controleId,
    comp,
    stateData: data,
    stateErrors: errors,
    isDisabled,
    handeOnChange,
  } = props;

  return (
    <div
      className={`form-group ${comp.isRequired && "required"}`}
      role="radiogroup"
      aria-labelledby={controleId + "_group_label"}
      id={controleId + "_rg"}
    >
      <label
        className="form-control-label mr-2"
        id={controleId + "_group_label"}
      >
        {comp.label}
      </label>
      {comp.radioValues.map((item, key) => (
        <div className="custom-control custom-radio mb-2">
          <input
            tabIndex={key}
            name={controleId}
            className={comp.cssclass ? comp.cssclass + " custom-control-input" : "custom-control-input"}
            id={controleId + key}
            type="radio"
            onChange={handeOnChange}
            value={item.value}
            //defaultChecked={data[controleId] === item.value}
            checked={data[controleId] === item.value}
            disabled={isDisabled}
            aria-labelledby={controleId + key}
            aria-checked={data[controleId] === item.value}
          />
          <label
            className="custom-control-label mr-2 pt-1"
            htmlFor={controleId + key}
          >
            {item.name}
          </label>
        </div>
      ))}
      {errors[controleId] && (
        <div className="alert alert-danger">{errors[controleId]}</div>
      )}
    </div>
  );
}

export default Radio;