import React, { Component, useEffect, useState } from "react";
import PageHeader from "../Comman/PageHeader";
import AddNewTimeLog from "./AddNewTimeLog";
import CandidateDetails from "./CandidateDetails";
import auth from "../../Services/authService";
import http from "../../Services/httpService";
import config from "../../config.json";
import ApplicationInstructions from "../ApplicationInstructions";
import Moment from "react-moment";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getFieldData } from "../../Services/appService";
import { getBackgroundChecks, loadBackgroundChecks } from "../../app/store/candidate";
import OnboardingSteps from "../../app/components/Onboarding/OnboardingSteps";
import CandidateInfo from "../../app/components/CandidateInfo";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../app/store/tools";
import useDocDownload from "../../app/hooks/useDocDownload";

export default function BackgroundChecks() {

  const route = [
    { name: "Task Management", url: "" },
    { name: "Onboarding Steps", url: "" },
  ];

  const [bcChecks, setBcChecks] = useState([]);
  const [entityType, setEntityType] = useState("");

  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const backgroundCheckSelector = useSelector(getBackgroundChecks);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);

  const [handleDownloadFile] = useDocDownload();

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  let count = entityType !== "C2C Basis" ? "4" : "3";

  useEffect(() => {
    dispatch(setCurrentPageTitle(`STEP-${count} : Background Check`));
    dispatch(setCurrentRoute(route))
  }, [])

  useEffect(() => {
    if (!userDetailsSelector)
      dispatch(loadUserDetails(currentUser.id));
    dispatch(loadBackgroundChecks(currentUser.id));
  }, []);

  useEffect(() => {
    if (userDetailsSelector) {
      const type = getFieldData(userDetailsSelector.performerData, "candidateonboardingform", "CandidateEntity");
      setEntityType(type);
    }
  }, [userDetailsSelector]);

  useEffect(() => {
    if (backgroundCheckSelector.length > 0) {
      const filterBc = backgroundCheckSelector.filter((x) => x.sendStatus === true);

      if (filterBc.length > 0) {
        setBcChecks(filterBc);
        let bcItems = "";
        filterBc.map((x, key) => {
          if (key > 0) bcItems += ",";
          bcItems += x.id;
        });
        handleUpdateComponent(bcItems, filterBc[0].ciid);
      }
    }
  }, [backgroundCheckSelector]);

  const handleUpdateComponent = (compItems, ciid) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === 26);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: "Background Checks",
      performerId: currentUser.id,
      compItems: compItems,
      ciid: ciid,
      wiid: 0,
      iiid: 0,
      compId: 26
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  return (
    <React.Fragment>
      <div className="col-md-12 mt-2">
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <i className="fa fa-square wf-phase2-box mr-2" aria-hidden="true"></i>
            <span className="h3">
              Background Check
            </span>
          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Background Check Name</th>
                    <th>Status</th>
                    <th>Document Name</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {bcChecks.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        {item.status === true && (
                          <span>
                            Passed
                          </span>
                        )}
                        {item.status === false && (
                          <span>
                            Failed
                          </span>
                        )}
                        {item.status === null && (
                          <span>
                            In-Process
                          </span>
                        )}
                      </td>
                      <td className="p-2">
                        {item.docList.map((doc) => (
                          <li className="list-group-item p-1">
                            <button
                              className="btn btn-link btn-sm"
                              onClick={() => handleDownloadFile(doc)}
                            >
                              {doc.documentName}
                            </button>
                          </li>
                        ))}
                      </td>
                      <td>
                        {item.instructionNotes}
                        <br />
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.updatedDatetime}
                        </Moment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
