import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "support",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        msgSendStatus: false,
        supportBprmForm: null,
        currentSupportWfTask: null,
        supportAppStartedData: null,
    },
    reducers: {
        supportTicketReceived: (support, action) => {
            support.list = action.payload;
        },
        supportTicketMessageSent: (support, action) => {
            support.msgSendStatus = action.payload;
        },
        supportBprmFormReceived: (support, action) => {
            support.supportBprmForm = action.payload;
        },
        currentSupportWfTaskReceived: (workflowEngine, action) => {
            workflowEngine.currentSupportWfTask = action.payload;
        },
        supportAppStartedDataReceived: (workflowEngine, action) => {
            workflowEngine.supportAppStartedData = action.payload;
        },
    }
});
export const { supportTicketReceived, supportTicketMessageSent,
    supportBprmFormReceived, currentSupportWfTaskReceived, supportAppStartedDataReceived } = slice.actions;
export default slice.reducer;

export const loadSupportTickets = (id, pageSize, pageNumber) => apiCallBegan({
    url: config.GetIssueDefectList + id + "&pmId=" + config.WorkflowIds.SupportPmId +
        "&pageSize=" + pageSize + "&pageNumber=" + pageNumber,
    onSuccess: supportTicketReceived.type
});

export const sendSupportTicketsMessage = (data) => apiCallBegan({
    url: config.SendSupportMessage,
    method: "post",
    data: data,
    onSuccess: supportTicketMessageSent.type
});

export const loadSupportBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: supportBprmFormReceived.type
});

export const loadSupportCurrentWorkflowTask = (data) => apiCallBegan({
    url: config.GetCurrentWorkflowTask,
    method: "post",
    data: data,
    onSuccess: currentSupportWfTaskReceived.type
});

export const startSupportApplication = (data) => apiCallBegan({
    url: config.StartNewApplication,
    method: "post",
    data: data,
    onSuccess: supportAppStartedDataReceived.type
});

export const getSupportTickets = createSelector(
    state => state.entities.support,
    support => support.list
);

export const getSupportBprmForm = createSelector(
    state => state.entities.support,
    support => support.supportBprmForm
);

export const getCurrentSupportWorkflowTask = createSelector(
    state => state.entities.support,
    support => support.currentSupportWfTask
);

export const getStartedSupportAppData = createSelector(
    state => state.entities.support,
    support => support.supportAppStartedData
);


