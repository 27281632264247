import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import auth from "../../Services/authService";
import config from "../../config.json";
import http from "../../Services/httpService";
import { getFieldData } from "../../Services/appService";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { getComponentUpdateStatus, getComponentUserStatus, getToolsCount, loadComponentUserStatus, loadToolsCount } from "../../app/store/tools";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps } from "../../app/store/candidate";

const Menu = () => {

  const state = {
    isSelected: null,
    currentUser: "",
    docsCount: 0,
    bcCount: 0,
    recDoc: 0,
    sentDoc: 0,
    frmLibraryDoc: 0,
    achFrmLibraryDoc: 0,
    insFrmLibraryDoc: 0,
    meetingCount: 0,
    msgCount: 0,
    poOrderCount: 0,
  };

  const [entityType, setEntityType] = useState("");
  const [rptNotify, setRptNotify] = useState("");
  const [toolsCounts, setToolsCounts] = useState([]);
  const [appTasklist, setAppTasklist] = useState([]);
  const [allPhases, setAllPhases] = useState([]);

  const userDetailSelector = useSelector(getLoggedUserDetails);
  const toolsCountSelector = useSelector(getToolsCount);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);
  const componentUpdateStatusSelector = useSelector(getComponentUpdateStatus);
  const candidateAppsSelector = useSelector(getCandidateApps);
  const appTasksSelector = useSelector(getAppTasks);

  const usrType = localStorage.getItem("userType");
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if(currentUser?.type !== "admin"){
      //dispatch(loadUserDetails(currentUser.id));
      dispatch(loadToolsCount(currentUser.id));
      if (candidateAppsSelector.length === 0)
        dispatch(loadCandidateApps(currentUser.id));
    }
  }, []);

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        if (appTasksSelector.length === 0)
          dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      let phs = [];

      const filterTasks = appTasksSelector.filter(x => x.wiid > 0)
        .sort((a, b) => a.orderNumber - b.orderNumber);

      filterTasks.map((p, key) => {
        if (phs.findIndex(x => x.wfPhase === p.wfPhase) === -1)
          phs.push({ id: key + 1, wfPhase: p.wfPhase });
      })
      if(usrType !== "candidate")
        phs=phs.filter(x=>x.wfPhase !== "Approved");
      setAllPhases(phs);
      setAppTasklist(filterTasks);
    }
  }, [appTasksSelector]);

  useEffect(() => {
    if (userDetailSelector) {
      const entType = getFieldData(
        userDetailSelector.performerData,
        "candidateonboardingform",
        "CandidateEntity"
      );
      setEntityType(entType);
      const isSent = getFieldData(
        userDetailSelector.performerData,
        "reportingdetails",
        "isSent"
      );
      setRptNotify(isSent);
    }
  }, [userDetailSelector]);

  useEffect(() => {
    if (toolsCountSelector.length > 0) {
      setToolsCounts(toolsCountSelector);
      const appObj = toolsCountSelector.filter(x => x.key === 0);
      if (appObj.length > 0) {
        dispatch(loadComponentUserStatus(currentUser.id, appObj[0].value));
      }
    }
  }, [toolsCountSelector]);

  const getCount = (id) => {
    const toolObj = toolsCountSelector.filter(x => x.key == id);
    if (toolObj.length > 0) return toolObj[0].value;
    return 0;
  }

  const getToolCount = (id) => {
    const compObj = componentUserStatusSelector.filter(x => x.compId === id);
    if (compObj.length > 0) {
      const unreadComps = compObj[0].unreadCompItems.split(',').sort();
      const readComps = compObj[0].compItems.split(',').sort();
      let unReadCount = 0;
      unreadComps.filter(x => x !== "").map(x => {
        const rs = readComps.filter(y => y === x);
        if (rs.length === 0) {
          unReadCount += 1;
        }
      });
      if (unReadCount === 0) {
        return <span className="badge badge-pill badge-success ml-1 text-dark">
          {getCount(id)}
        </span>
      }
      else {
        return <span className="badge badge-pill badge-danger ml-1">
          {unReadCount}
        </span>
      }

    } else {
      return <span className="badge badge-pill badge-danger ml-1">
        {getCount(id)}
      </span>
    }
  }

  useEffect(() => {
    if (componentUpdateStatusSelector) {
      dispatch(loadToolsCount(currentUser.id));
    }
  }, [componentUpdateStatusSelector])


  const getPhaseColor = (phase) => {
    const app = [];
    const totalPhases = allPhases;
    var keyCount = 0;
    let phaseBg = "btn-wf-default"
    totalPhases.map((item) => {
      let keyNum = 5;
      if (item.wfPhase === "Review" || item.wfPhase === "Onboarding")
        keyNum = 2;
      if (item.wfPhase === "Approved")
        keyNum = 3
      if (item.wfPhase === "Denied")
        keyNum = 4;
      if (keyNum === 5) {
        keyNum += keyCount;
        keyCount += 1;
      }

      if (item.wfPhase === phase) {
        phaseBg = `btn-wf${keyNum}-default`
      }

    })

    return phaseBg;
  }

  return (
    <nav
      className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white shadow-none"
      id="sidenav-main"
    >
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          <a className="navbar-brand" href="/" style={{ padding: "12px", marginLeft: "18px" }}>
            <img
              src="/assets/img/wflogoblack.png"
              className="navbar-brand-img"
            />
          </a>

          <div className="ml-auto">
            <div
              className="sidenav-toggler d-none d-xl-block"
              data-action="sidenav-unpin"
              data-target="#sidenav-main"
              style={{ padding: "15px" }}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner" style={{ marginTop: "-18px" }}>
          <div
            className="collapse navbar-collapse"
            id="sidenav-collapse-main"
          >
            <ul className="navbar-nav">
              {usrType !== "candidate" && (
                <React.Fragment>
                  <li className="nav-item">
                    <h6
                      className="navbar-heading mb-0 btn p-2 rounded-0 text-left text-dark border-right-0"
                      style={{ border: "1px solid #ccc", fontWeight: "bold" }}
                    >
                      Administration
                    </h6>
                  </li>
                  <li className="nav-item">
                  <NavLink
                      to="/ManagePartnerUsers"
                      className="nav-link"
                      title="You may add as many users to access the portal."
                    >
                      <i className="fa fa-user" 
                      style={{minWidth:"2rem",fontSize:"22px",marginLeft:"-10px"}} 
                      aria-hidden="true"></i>Manage Users
                    </NavLink>
                  </li>
                 
                </React.Fragment>
              )}

              <li className="nav-item">
                <h6
                  className="navbar-heading mb-0 btn p-2 rounded-0 text-left text-dark"
                  style={{ borderBottom: "1px solid #dee2e6", borderTop: "1px solid #dee2e6", fontWeight: "bold" }}
                >
                  {usrType === "candidate"
                    ? "Task Management"
                    : "Contract Management"}
                </h6>
              </li>

              {allPhases.map(p => {
                return <li className="nav-item">
                  <h3 className="nav-link active">
                    <div className={`${getPhaseColor(p.wfPhase)} rounded border wf-box`}></div>
                    {p.wfPhase}
                  </h3>
                  <div className="collapse show mt--2" id="navbar-manageCip">
                    <ul className="nav flex-column">
                      {appTasklist.filter(x => x.wfPhase === p.wfPhase).map((tsk, key) => {
                        if (tsk.taskName === "Onboarding/Enrollment") {

                          return <li className="nav-item">
                            <NavLink to="/Onboarding" className="nav-link stepMenu">
                              {tsk.taskName}
                            </NavLink>
                          </li>
                        }
                        else if (tsk.taskName === "Contract Management") {
                          return <li className="nav-item">
                            <NavLink to="/ContractDocs" className="nav-link stepMenu">
                              {tsk.taskName} {getToolCount(3)}
                            </NavLink>
                            {usrType !== "candidate" && <ul className="nav nav-sm flex-column">
                              <li className="nav-item">
                                <NavLink to="/AchInfo" className="nav-link">
                                  ACH Information
                                  {getToolCount(20)}
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink to="/InsuranceInfo" className="nav-link">
                                  Insurance Information
                                  {getToolCount(21)}
                                </NavLink>
                              </li>
                            </ul>}

                            {usrType === "candidate" && entityType !== "C2C Basis" && 
                            <ul className="nav nav-sm flex-column">
                              <li className="nav-item">
                                <NavLink to="/AchInfo" className="nav-link">
                                  ACH Information
                                  {getToolCount(20)}
                                </NavLink>
                              </li>
                            </ul>}
                          </li>
                        }
                        else if (tsk.taskName === "Background Check") {
                          return <li className="nav-item">
                            <NavLink to="/BackgroundChecks" className="nav-link stepMenu">
                              {tsk.taskName} {getToolCount(26)}
                            </NavLink>
                          </li>
                        }
                        else if (tsk.taskName === "Review") {
                          return <li className="nav-item">
                            <NavLink to="/OnboardingStatus" className="nav-link stepMenu">
                            Onbording/Enrollment Status
                            </NavLink>
                          </li>
                        }
                        else if (tsk.taskName === "Reporting Details") {
                          return <li className="nav-item">
                            <NavLink to="/ReportingDetails" className="nav-link stepMenu">
                              {tsk.taskName}
                            </NavLink>
                          </li>
                        }
                        else if (tsk.wfPhase === "Time Log" && usrType === "candidate") {
                          return <li className="nav-item">
                            <NavLink to="/TimeLogs" className="nav-link stepMenu">
                              {tsk.taskName}
                            </NavLink>
                          </li>
                        }
                        else if (tsk.wfPhase === "Time Log" && usrType !== "candidate") {
                          return <li className="nav-item">
                            <NavLink to="/PartnerCandidates" className="nav-link stepMenu">
                              {tsk.taskName}
                            </NavLink>
                          </li>
                        }
                        else if (tsk.wfPhase === "Invoice" && usrType !== "candidate") {
                          return <li className="nav-item">
                            <NavLink to="/PartnerInvoices" className="nav-link stepMenu">
                              {tsk.taskName}
                            </NavLink>
                          </li>
                        }
                        else if (tsk.wfPhase === "Compliance") {
                          return <li className="nav-item">
                            <NavLink to="/Compliance" className="nav-link stepMenu">
                              {tsk.taskName}
                            </NavLink>
                          </li>
                        }
                        else {
                          return <li className="nav-item">
                            <NavLink to="/" className="nav-link stepMenu">
                              {tsk.taskName}
                            </NavLink>
                          </li>
                        }
                      })}
                    </ul>
                  </div>
                </li>
              })}

              {/* <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  title={`Please complete the following for Onboarding/Enrollment.`}>
                  <i className="fa fa-list-ul" aria-hidden="true"></i>
                  Onboarding/Enrollment
                </NavLink>
                <div className="collapse show" id="navbar-manageCip">
                  <ul className="nav nav-sm flex-column">
                    {usrType === "candidate" ? (
                      <React.Fragment>

                        <li className="nav-item">
                          <NavLink to="/Onboarding" className="nav-link stepMenu">
                            1 : Onboarding Form
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/ContractDocs" className="nav-link stepMenu">
                            2 : Contract Documents
                            {getToolCount(3)}
                          </NavLink>
                        </li>
                        {entityType !== "C2C Basis" && (
                          <li className="nav-item">
                            <NavLink to="/AchInfo" className="nav-link stepMenu">
                              3 : ACH Information
                              {getToolCount(20)}
                            </NavLink>
                          </li>
                        )}

                        <li className="nav-item">
                          <NavLink
                            to="/BackgroundChecks"
                            className="nav-link stepMenu"
                          >
                            Background Check
                            {getToolCount(26)}
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink
                            to="/ReportingDetails"
                            className="nav-link stepMenu"
                          >
                            Reporting Details
                            <span className="badge badge-pill badge-danger ml-1">
                              {rptNotify}
                            </span>
                          </NavLink>
                        </li>

                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <li className="nav-item">
                          <NavLink to="/Onboarding" className="nav-link stepMenu">
                            1 : Enrollment Form
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/ContractDocs" className="nav-link stepMenu">
                            2 : Contract Documents
                            {getToolCount(3)}
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink to="/AchInfo" className="nav-link stepMenu">
                            3 : ACH Information
                            {getToolCount(20)}
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/InsuranceInfo" className="nav-link stepMenu">
                            4 : Insurance Information
                            {getToolCount(21)}
                          </NavLink>
                        </li>

                      </React.Fragment>
                    )}
                  </ul>
                </div>
              </li> */}

              {/* {usrType !== "candidate" && (
                <React.Fragment>
                  <li className="nav-item">
                    <NavLink to="/OnboardingStatus" className="nav-link">
                      <i className="fa fa-check-square-o" aria-hidden="true"></i> Enrollment Status
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/PartnerCandidates"
                      className="nav-link"
                      title="This list provides the number of candidates engaged on the projects."
                    >
                      <i className="fa fa-users" aria-hidden="true"></i> Candidate List
                      {getToolCount(19)}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/PartnerInvoices"
                      className="nav-link"
                      title="Generate automatic Invoices based on the Time Logged by the candidates. 
                      You are not required to attach time sheets or Upload Invoices. 
                      Our system does this automatically. 
                      Payments updates will also be seen in this section."
                    >
                      <i className="fa fa-file-text" aria-hidden="true"></i>
                      Invoices and Payments {getToolCount(23)}
                    </NavLink>

                  </li>
                </React.Fragment>
              )} */}

              {/* {usrType === "candidate" && (
                <li className="nav-item">
                  <NavLink to="/OnboardingStatus" className="nav-link">
                  <i className="fa fa-check-square-o" aria-hidden="true"></i> Onboarding Status
                  </NavLink>
                  <NavLink
                    to="/Compliance"
                    className="nav-link"
                    title="In this section you will review the compliance documents."
                  >
                    <i className="fa fa-gavel" aria-hidden="true"></i>
                    Compliance Management
                  </NavLink>
                  <NavLink
                    to="/TimeLogs"
                    className="nav-link"
                    title="In this section enter your daily hours logged on the project."
                  >
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    Time Log {entityType === "C2C Basis"
                      ? "Management"
                      : "and Payments"}
                  </NavLink>
                </li>
              )} */}

              <li className="nav-item">
                <h3 className="nav-link active">
                  <div className={`btn-default rounded border shadow-none wf-box`}></div>
                  Tools
                </h3>
                <div className="collapse show mt--2" id="navbar-manageCip">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <NavLink
                        to="/IndividualMsg"
                        className="nav-link stepMenu"
                        title="Use this feature for to and fro communication"
                      >

                        Message(s)
                        {getToolCount(9)}
                      </NavLink>

                    </li>

                    <li className="nav-item">
                      <NavLink to="/FormLibrary" className="nav-link stepMenu"
                        title="Repository of fillable forms to be completed.">

                        Forms
                        {getToolCount(22)}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to="/SentDocs"
                        className="nav-link stepMenu"
                        title="View history of documents sent and received."
                      >

                        Document History
                        {getToolCount(2)}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      {entityType !== "C2C Basis" && (
                        <>
                          <NavLink
                            to="/ComplianceRpt"
                            className="nav-link stepMenu"
                            title="In this section you will receive yearly compliance document (1099-NEC)."
                          >

                            Compliance {getToolCount(25)}
                          </NavLink>
                          <NavLink
                            to="/CustomReport"
                            className="nav-link stepMenu"
                            title="View standard reports."
                          >
                            Reports
                          </NavLink>
                        </>
                      )}

                      <NavLink
                        to="/ScheduleMeeting"
                        className="nav-link stepMenu"
                        title="In the event any appointment is scheduled you will accept the same in this section."
                      >

                        Schedule Appointments
                        {getToolCount(11)}
                      </NavLink>
                      <NavLink
                        to="/SurveyFeedback"
                        className="nav-link stepMenu"
                        title="Complete the surveys as part of total customer satisfaction."
                      >
                        Surveys
                      </NavLink>

                    </li>
                  </ul>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
