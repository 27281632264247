import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import PageHeader from "../Comman/PageHeader";
import UploadDocPopup from "../UploadDocPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { getFieldData, getFormatedNumber } from "../../Services/appService";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps, loadCandidatesByFilter } from "../../app/store/candidate";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../app/store/auth";
import { getAlert } from "../../Services/appAlerts";
import {
  getPartCandidateAppTasks,
  getPartnerCandidateApps,
  getPartnerFilterCandidate, getUpdateDocSentStatus,
  loadCandidateAppRuningTasks,
  loadPartnerCandidateApps,
  loadPartnerFilterCandidate, partnerCandidateDocSend
} from "../../app/store/partner";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import useDocDownload from "../../app/hooks/useDocDownload";

export default function CandidateWorkingHub() {
  const route = [
    { name: "Contract Management", url: "" },
    { name: "List of Candidates", url: "/PartnerCandidates" },
  ];

  const reqDocuments = [
    {
      id: 1,
      date: "11/27/2021",
      name: "Copy of Candidate's e-verify approval",
      size: "1.3 Kb",
      type: "pdf",
      status: false,
      uploaded: 1,
      docusign: false,
      type: "",
      download: false,
    },
    {
      id: 2,
      date: "11/27/2021",
      name: "Copy of Candidate's approved I-9",
      size: "1.3 Kb",
      type: "pdf",
      status: false,
      uploaded: 1,
      docusign: false,
      type: "",
      download: false,
    },
    {
      id: 3,
      date: "11/27/2021",
      name: "Copy of VISA Status",
      size: "1.3 Kb",
      type: "pdf",
      status: false,
      uploaded: 1,
      docusign: false,
      type: "",
      download: false,
    },
    {
      id: 4,
      date: "11/27/2021",
      name: "Other",
      size: "1.3 Kb",
      type: "pdf",
      status: false,
      uploaded: 1,
      docusign: false,
      type: "",
      download: false,
    },
  ];

  const [candidateObj, setCandidateObj] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [wfTask, setWfTask] = useState(null);
  const [docShowHide, setDocShowHide] = useState(false);

  const partCandidateAppsSelector = useSelector(getPartnerCandidateApps);
  const partCandidateAppTasksSelector = useSelector(getPartCandidateAppTasks);
  const docSendStatus = useSelector(getUpdateDocSentStatus);
  const partnerFilterCandidateSelector = useSelector(getPartnerFilterCandidate);

  const [handleDownloadFile] = useDocDownload();

  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Candidate Upload Documents"));
    dispatch(setCurrentRoute(route))
  }, [])

  useEffect(() => {
    const state = location.state;
    console.log(state);
    if (state) {
      setSelectedItem(state);
      if (state.subcontractor?.fuid && state.performerid) {
        dispatch(loadPartnerFilterCandidate(state.subcontractor?.fuid, state.performerid));
        dispatch(loadPartnerCandidateApps(state.performerid));
      }
    }
  }, []);

  useEffect(() => {
    if (partnerFilterCandidateSelector.length > 0) {
      setCandidateObj(partnerFilterCandidateSelector[0]);
    }
  }, [partnerFilterCandidateSelector])

  useEffect(() => {
    if (selectedItem && partCandidateAppsSelector.length > 0) {
      const mainWorkflow = partCandidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        dispatch(loadCandidateAppRuningTasks(wf.oldWorkflowId, selectedItem.performerid));
      }
    }
  }, [partCandidateAppsSelector]);

  useEffect(() => {
    if (partCandidateAppTasksSelector.length > 0) {
      const filterTasks = partCandidateAppTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
      if (filterTasks.length > 0) {
        setWfTask(filterTasks[0]);
      }
    }
    else {
      setWfTask(null);
    }
  }, [partCandidateAppTasksSelector]);

  useEffect(() => {
    if (docSendStatus === "success") {
      getAlert("", "Sent Successfully", "success");
      if (selectedItem.subcontractor?.fuid)
        dispatch(loadPartnerFilterCandidate(selectedItem.subcontractor?.fuid, selectedItem.performerid));
    }
  }, [docSendStatus])

  const handleUploadDocShowHide = (docs) => {
    if (docs === "save") {
      setDocShowHide(false);
      setSelectedDoc(null);
      dispatch(loadPartnerFilterCandidate(selectedItem.subcontractor?.fuid, selectedItem.performerid));
    } else {
      setDocShowHide(!docShowHide);
      setSelectedDoc(docs);
    }
  };

  const handleSendDoc = async (id) => {
    const docIds = [id];
    const frm = new FormData();
    frm.append("ids", docIds);
    frm.append("userId", currentUser.id);
    dispatch(partnerCandidateDocSend(frm));
  };

  const getUploadStatus = (doc) => {
    if (candidateObj) {
      const docs = candidateObj.docList.filter(
        (x) => x.type === `${doc.id}_c2c_${candidateObj.loanamountlimit}`
      );

      if (docs.length === 0 && wfTask) {
        return <button
          className="btn btn-link btn-sm p-0"
          onClick={() => handleUploadDocShowHide(doc)
          }
        >
          Upload
        </button>
      }
    }
    return "";
  }

  const getUploadedDoc = (doc) => {
    if (candidateObj) {
      const findDoc = candidateObj.docList.filter((x) => x.type === `${doc.id}_c2c_${candidateObj.loanamountlimit}`);
      if (findDoc.length > 0) {
        const x = findDoc[0];
        return (
          <>
            <td>
              <button
                className="btn btn-sm btn-link p-0"
                title="Download Document"
                onClick={() => handleDownloadFile(x)}
              >
                {x.documentName}
              </button>
            </td>
            <td>
              {x.sendStatus ? (
                <span>
                  <i className="fa fa-check mr-1" aria-hidden="true"></i>
                  Sent
                </span>
              ) : (
                <button
                  className="btn btn-sm btn-link"
                  onClick={() => handleSendDoc(x.id)}
                >
                  Send
                </button>
              )}
              <br />
              {x.sendDateTime && (
                <Moment format="MMM Do YYYY">{x.sentDateTime}</Moment>
              )}
            </td>
          </>
        );
      }
    }

    return (
      <>
        <td></td>
        <td></td>
      </>
    );
  };

  let item = "";
  if (candidateObj)
    item = candidateObj;

  const usrType = localStorage.getItem("userType");
  return (
    <React.Fragment>
      <div className="col-md-12 text-right">
        <button
          className="btn btn-sm btn-outline-default btn-min-width mb-2 mt-2"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
        </button>
      </div>
      <div className="col-md-12 form-group">
        <div className="table-responsive">
          <table className="table align-items-center table-flush table-hover table-striped mb-2 border">
            <thead className="thead-light">
              <tr>
                <th>Candidate #<br />IPCS ID</th>
                <th>Candidate Details</th>
                <th>Agency / Client</th>
                <th>Duration</th>
                <th>Onboarding Status</th>
              </tr>
            </thead>
            <tbody>
              {item !== "" &&
                <tr>
                  <td>
                    {item.performerid}
                    <br />
                    {item.maxloans}
                  </td>
                  <td>
                    <span className="text-uppercase font-weight-bold">
                      {item.performername} {item.lastName}
                    </span>
                    <br />
                    <a href="#">{item.performeremail}</a>
                    <br />
                    {item.contactNo && getFormatedNumber(item.contactNo)}
                    <br />
                    {getFieldData(
                      item.performerData,
                      "projectengagement",
                      "PositionTitle"
                    )}
                  </td>
                  <td>
                    {getFieldData(
                      item.performerData,
                      "projectengagement",
                      "AgencyName"
                    )}
                  </td>
                  <td>
                    {item.duration !== null && (
                      <React.Fragment>
                        {item.duration.duration} Months
                        <br />
                        Start :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.startDate}
                        </Moment>
                        <br />
                        End :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.endDate}
                        </Moment>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {item.appSubmit && (
                      <span>
                        Complete
                      </span>
                    )}
                    {!item.appSubmit && (
                      <span>
                        Pending
                      </span>
                    )}
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <i className="fa fa-square wf-phase5-box mr-2" aria-hidden="true"></i>
            <span className="h3">
              Upload the below required documents for this
              candidate
            </span>

          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped border">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Required</th>
                    <th></th>
                    <th>Uploaded</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reqDocuments.map((doc, key) => (
                    <tr>
                      <td>{doc.id}</td>
                      <td>{doc.name}</td>
                      <td>
                        {getUploadStatus(doc)}
                      </td>
                      {getUploadedDoc(doc)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {docShowHide &&
        <UploadDocPopup
          uploadDocShowHide={docShowHide}
          handleUploadDocModalShowHide={handleUploadDocShowHide}
          runningApplication={[wfTask]}
          selectedDocType={selectedDoc ? `${selectedDoc.id}_c2c_${usrType === "recruiter" ? candidateObj.activeloans : candidateObj.loanamountlimit}` : ""}
          messageAlert="Added Successfully"
        ></UploadDocPopup>}
    </React.Fragment>
  );
}
