import React, { useEffect, useState } from "react"
import Moment from "react-moment"
import { getFieldData } from "../../../Services/appService"
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../store/auth";
import {
  getAppDocuments,
  getAppFormLibrary, getAppTasks, getCandidateApps,
  loadAppDocuments,
  loadAppFormLibrary, loadAppRuningTasks, loadCandidateApps
} from "../../store/candidate";
import AddEditNotes from "../../../Components/ApplicantProcessing/AddEditNotes";
import { setCurrentPageTitle, setCurrentRoute } from "../../store/layoutUI";
import UploadDocPopup from "../../../Components/UploadDocPopup";
import ViewFormModal from "../ViewFormModal";
import useDocDownload from "../../hooks/useDocDownload";
import { allowFormLibraryEdit, getFormLibraryEditStatus } from "../../store/forms";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../store/tools";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getAlert } from "../../../Services/appAlerts";

export default function AchInfo() {

  const route = [
    { name: "Contract Management", url: "" },
    { name: "Enrollment Steps", url: "" },
  ];

  const [appFormLibrary, setAppFormLibrary] = useState([]);
  const [sentDocs, setSentDocs] = useState([]);
  const [wfTask, setWfTask] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const [formType, setFormType] = useState("ACH Information");
  const [formShowHide, setFormShowHide] = useState(false);
  const [formObj, setFormObj] = useState(null);
  const [noteShowHide, setNoteShowHide] = useState(false);
  const [notesType, setNotesType] = useState("formEditReq");
  const [appTasklist, setAppTasklist] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [docUploadShowHide, setDocUploadShowHide] = useState(false);
  const [reviewerTask, setReviewerTask] = useState(null);
  const [allowEdit, setAllowEdit] = useState(false);

  const candidateAppsSelector = useSelector(getCandidateApps);
  const appTasksSelector = useSelector(getAppTasks);
  const appFormLibrarySelector = useSelector(getAppFormLibrary);
  const appDocumentsSelector = useSelector(getAppDocuments);
  const formLibraryEditStatusSelector = useSelector(getFormLibraryEditStatus);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);

  const [handleDownloadFile] = useDocDownload();

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    dispatch(setCurrentPageTitle("STEP-3 : ACH Information"));
    dispatch(setCurrentRoute(route))
  }, [])

  useEffect(() => {
    if (candidateAppsSelector.length === 0)
      dispatch(loadCandidateApps(currentUser.id))
  }, []);

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        setWorkflow(wf);
        if (appFormLibrarySelector.length === 0)
          dispatch(loadAppFormLibrary(wf.oldWorkflowId, currentUser.id));
        if (appTasksSelector.length === 0)
          dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
        if (appDocumentsSelector.length === 0)
          dispatch(loadAppDocuments(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
      if (filterTasks.length > 0) {
        const reviewTasks = filterTasks.filter(x => x.registrationType === "WP");
        if (reviewTasks.length > 0) setReviewerTask(reviewTasks[reviewTasks.length - 1]);
        setAppTasklist(filterTasks);
        setWfTask(filterTasks[0]);
      }
    }
  }, [appTasksSelector]);

  useEffect(() => {
    if (appFormLibrarySelector.length > 0) {
      const frmLibrary = appFormLibrarySelector.filter(x => x.formType === formType);
      setAppFormLibrary(frmLibrary);

      if (frmLibrary.length > 0) {
        let frmItems = "";
        frmLibrary.map((x, key) => {
          if (key > 0) frmItems += ",";
          frmItems += x.id;
        });

        handleUpdateComponent(frmItems, frmLibrary[0].ciid);
      }
    }
  }, [appFormLibrarySelector]);

  useEffect(() => {
    if (appDocumentsSelector.length > 0) {
      const appDocs = appDocumentsSelector;
      let sentDocs = appDocs.filter(
        (x) =>
          x.type === "send" &&
          x.uploadType !== "Invoice" &&
          x.docType !== "internal" &&
          x.docType !== "Messaging"
      );
      setSentDocs(sentDocs);
    }
  }, [appDocumentsSelector])

  const getVoidCheck = (item) => {
    const achDocs = sentDocs.filter(
      (x) => x.docType === `ach_${item.id}`
    );

    if (achDocs.length === 0) {
      return <button
        className="btn btn-link btn-sm p-0"
        onClick={() =>
          handleUploadDocModalShowHide(`ach_${item.id}`)
        }
      >
        Upload
      </button>
    }

    if (achDocs.length > 0) {
      return <>
        {achDocs.map(doc =>
          <>
            <button
              className="btn btn-link btn-sm p-0"
              title="Download Document"
              onClick={() => handleDownloadFile(doc)
              }
            >
              {doc.documentName}
            </button>
            <br />
            {doc.notes}
          </>
        )}
      </>
    }
  }

  const handleUploadDocModalShowHide = (type) => {
    if (!reviewerTask) {
      getAlert("Warning", "No Reviewer Found. Please Complete Your Onboarding/Enrollment Form.", "warning");
      return;
    }
    if (type === "save" || type === "") {
      setDocUploadShowHide(false);
      setSelectedDocType("");
      if (workflow?.oldWorkflowId)
        dispatch(loadAppDocuments(workflow.oldWorkflowId, currentUser.id));
    } else {
      setDocUploadShowHide(true);
      setSelectedDocType(type);
    }
  };

  const handleFormShowHide = (frm) => {
    if (frm === "save") {
      setFormShowHide(false);
      setFormObj("");
      if (workflow) dispatch(loadAppRuningTasks(workflow.oldWorkflowId, currentUser.id));
    } else {
      setFormShowHide(!formShowHide);
      setFormObj(frm);
      if (frm?.allowEdit === 1) setAllowEdit(true);
    }
  };

  const handleNotesComments = (type, obj) => {
    if (type === "save") {
      setFormObj(null);
      setNoteShowHide(false);
      if (workflow?.oldWorkflowId > 0)
        dispatch(loadAppFormLibrary(workflow.oldWorkflowId, currentUser.id));
    } else {
      setFormObj(obj);
      setNoteShowHide(!noteShowHide);
    }
  };

  const handleFormUpdate = () => {
    setAllowEdit(false);
    if (formObj?.id > 0)
      dispatch(allowFormLibraryEdit(formObj.id, 0));
  }

  useEffect(() => {
    if (formLibraryEditStatusSelector) {
      if (workflow?.oldWorkflowId > 0)
        dispatch(loadAppFormLibrary(workflow.oldWorkflowId, currentUser.id));
    }
  }, [formLibraryEditStatusSelector]);

  const handleUpdateComponent = (compItems, ciid) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === 20);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: "ACH",
      performerId: currentUser.id,
      compItems: compItems,
      ciid: ciid,
      wiid: 0,
      iiid: 0,
      compId: 20
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  const getPopover = (notes) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h4">Notes</Popover.Title>
        <Popover.Content>{notes}</Popover.Content>
      </Popover>
    );
    return (
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <button className="btn btn-sm btn-link"> View</button>
      </OverlayTrigger>
    );
  };

  return <React.Fragment>

    <div className="col-md-12 mt-2 form-group">
      <div className="card shadow-none border">
        <div className="card-header p-3">
          <div className="row">
            <div className="col-md-8">
              <i className="fa fa-square wf-phase2-box mr-2" aria-hidden="true"></i>
              <span className="h3">
                ACH Information
              </span>
              <p className="text-red">
                Click on View/Edit button to complete the ACH form,
                and then click on submit button to send the
                document.
              </p>
            </div>
          </div>
        </div>
        <div className="card-body p-3">
          <div className="table-responsive">
            <table className="table align-items-center table-flush table-hover table-striped border">
              <thead className="thead-light">
                <tr>
                  <th>S.No.</th>
                  <th>Form Name</th>
                  <th>Notes</th>
                  <th>ACH Information</th>
                  <th>Last Updated</th>
                  <th>Action</th>
                  <th>Voided Check</th>
                </tr>
              </thead>
              <tbody>
                {appFormLibrary.map((item, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {item.formName}
                    </td>
                    <td>
                      {getPopover(item.remarks)}
                    </td>
                    <td>
                      Bank Name :{" "}
                      {wfTask && getFieldData(
                        wfTask.stateData,
                        "achinfo",
                        "Bank_Name"
                      )}
                      <br />
                      Routing Number :{" "}
                      {wfTask && getFieldData(
                        wfTask.stateData,
                        "achinfo",
                        "Routing"
                      )}
                      <br />
                      Acccount Number :{" "}
                      {wfTask && getFieldData(
                        wfTask.stateData,
                        "achinfo",
                        "Account_No"
                      )}
                    </td>
                    <td>
                      {item.lastUpdate && <Moment format="MMM Do YYYY, h:mm a">
                        {item.lastUpdate}
                      </Moment>}
                    </td>
                    <td>
                      <button
                        className="btn btn-link btn-sm p-0"
                        onClick={() => handleFormShowHide(item)
                        }
                      >
                        {item.allowEdit === 1
                          ? "View/Edit"
                          : "View"}
                      </button>
                      <br />
                      {item.allowEdit === 0 && (
                        <button
                          className="btn btn-link btn-sm p-0"
                          onClick={() => handleNotesComments("", item)}
                        >
                          Request Edit
                        </button>
                      )}
                      {item.allowEdit === 2 && (
                        <>
                          <span>Request Sent</span>
                          <br />
                          {item.editRemarks}
                        </>
                      )}
                    </td>
                    <td>
                      {getVoidCheck(item)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    {docUploadShowHide &&
      <UploadDocPopup
        uploadDocShowHide={docUploadShowHide}
        handleUploadDocModalShowHide={handleUploadDocModalShowHide}
        runningApplication={reviewerTask ? [reviewerTask] : []}
        selectedDocType={selectedDocType}
      ></UploadDocPopup>}

    {formShowHide && <ViewFormModal
      viewFormShowHide={formShowHide}
      handleViewForm={handleFormShowHide}
      workflowTask={wfTask}
      selectedForm={formObj}
      handleFormUpdate={handleFormUpdate}
      allowEdit={allowEdit}
    ></ViewFormModal>}

    {/* {formShowHide && <ViewForm
      formShowHide={formShowHide}
      handleFormShowHide={handleFormShowHide}
      workflowTask={wfTask}
      selectedForm={formObj}
    ></ViewForm>} */}

    {noteShowHide && <AddEditNotes
      noteShowHide={noteShowHide}
      handleNoteShowHide={handleNotesComments}
      notesType={notesType}
      candidateObj={currentUser}
      selectedObj={formObj}
    ></AddEditNotes>}
  </React.Fragment>
}