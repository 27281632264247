import React, { Component, useEffect, useState } from "react";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import PageHeader from "../Comman/PageHeader";
import AddNewTimeLog from "./AddNewTimeLog";
import CandidateDetails from "./CandidateDetails";
import Moment from "react-moment";
import WeekLogView from "./WeekLogView";
import AddEditNotes from "./AddEditNotes";
import CandidatePay from "./CandidatePay";
import AddTimeLog from "./AddTimeLog";
import Swal from "sweetalert2";
import { getFieldData, getMonthLastDay, getMonthName, getTotalDays, getTotalHours } from "../../Services/appService";
import { getAlert, getConfirmAlert } from "../../Services/appAlerts";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { deleteTimeSheetDoc, getProjectDateUpdateStatus, getTimeLogDuration, getTimeLogs, getTimeSheetDelStatus, loadTimeLogDuration, loadTimeLogs, updateProjectStartDate } from "../../app/store/timeLog";
import { getTimeLogInvoice, loadTimeLogInvoice } from "../../app/store/timeLogInvoice";
import CandidateInfo from "../../app/components/CandidateInfo";
import useDocDownload from "../../app/hooks/useDocDownload";

export default function TimeLogs() {

  const [timeLogInvoices, setTimeLogInvoices] = useState([]);
  const [timeLogs, setTimeLogs] = useState([]);
  const [invType, setInvType] = useState("subcontractor");
  const [entityType, setEntityType] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectStDt, setProjectStDt] = useState("");
  const [currentStDt, setCurrentStDt] = useState("");
  const [notesType, setNotesType] = useState("TimeLog");
  const [showHide, setShowHide] = useState(false);
  const [noteShowHide, setNoteShowHide] = useState(false);
  const [payShowHide, setPayShowHide] = useState(false);
  const [showHideTimeLog, setShowHideTimeLog] = useState(false);
  const [timeLogObj, setTimeLogObj] = useState(null);
  const [candidateObj, setCandidateObj] = useState(null);

  const timeLogsSelector = useSelector(getTimeLogs);
  const timeLogInvoiceSelector = useSelector(getTimeLogInvoice);
  const timeLogDurationSelector = useSelector(getTimeLogDuration);
  const updateStatusSelector = useSelector(getProjectDateUpdateStatus);
  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const timeSheetDelStatusSelector = useSelector(getTimeSheetDelStatus);

  const [handleDownloadFile] = useDocDownload();

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userDetailsSelector)
      dispatch(loadUserDetails(currentUser.id));

    dispatch(loadTimeLogs(currentUser.id));
    dispatch(loadTimeLogInvoice(currentUser.id));
    dispatch(loadTimeLogDuration(currentUser.id));

  }, []);

  useEffect(() => {
    if (userDetailsSelector) {
      const cnObj = userDetailsSelector;
      const entityType = getFieldData(cnObj.performerData, "candidateonboardingform", "CandidateEntity");
      setCandidateObj(cnObj);
      setEntityType(entityType);
    }
  }, [userDetailsSelector])

  useEffect(() => {
    if (timeLogsSelector.length > 0)
      setTimeLogs(timeLogsSelector);
    if (timeLogInvoiceSelector.length > 0) {
      const filterInvoices = timeLogInvoiceSelector.filter(x => x.invoiceType === invType);
      setTimeLogInvoices(filterInvoices);
    }
    if (timeLogDurationSelector?.projectStartedDate) {
      setProjectStDt(timeLogDurationSelector.projectStartedDate);
      setCurrentStDt(timeLogDurationSelector.projectStartedDate);
    }

  }, [timeLogsSelector,
    timeLogInvoiceSelector,
    timeLogDurationSelector]);

  useEffect(() => {
    if (updateStatusSelector) {
      getAlert("", "Saved Successfully", "success");
      dispatch(loadTimeLogDuration(currentUser.id));
    }
  }, [updateStatusSelector])


  const getInvoiceStatus = (item) => {
    const tmLogs = timeLogInvoices.filter(
      (x) =>
        x.timeLogId === item.id &&
        x.status !== null
    )
    if (tmLogs.length === 0) {
      return <span>
        Review Pending
      </span>
    }

    return <>
      {tmLogs.map((inv) => (
        <React.Fragment>
          <span>
            Reviewed
          </span>
          <br />
          {inv.status === true && (
            <span>
              Approved
            </span>
          )}
          {inv.status === false && (
            <span>
              Rejected
            </span>
          )}
          <br />
          <Moment format="MMM Do YYYY">
            {inv.reviewDateTime}
          </Moment>
        </React.Fragment>
      ))}
    </>
  }

  const getPaymentStatus = (item) => {
    const tmLogs = timeLogInvoices.filter(
      (x) => x.timeLogId === item.id);

    if (tmLogs.length === 0) return "";
    return <>
      {tmLogs.map((pay) => (
        <React.Fragment>
          {pay.paymentMode && (
            <>
              {pay.docList?.map((doc) => (
                <button
                  className="btn btn-sm btn-link p-0 text-underline"
                  onClick={() => handleDownloadFile(doc)}
                >
                  {doc.documentName}
                </button>
              ))}
              {pay.docList > 0 && <br />}
              <Moment format="MMM Do YYYY, h:mm a">
                {pay.paymentDate}
              </Moment>
              <br />
              {entityType === "W2 Basis" && (
                <>
                  
                  Deduction: {parseFloat(pay.adpDeduction).toFixed(2)}
                </>
              )}
              <br />
              Paid :{" "}
              {parseFloat(pay.paidAmount).toFixed(2)}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  }

  const getPerDiemStatus = (item) => {
    const prd = getFieldData(
      candidateObj?.performerData,
      "candidateonboardingform",
      "Per_Diem_Rate"
    )
    return <>
      {prd}
      <br />
      {prd * getTotalDays(item.workingHrs)}
    </>

  }

  const getTimeLogStatus = (item) => {
    let tmLogs = timeLogInvoices.filter(
      (x) =>
        x.timeLogId === item.id &&
        x.paidAmount > 0
    );

    if (tmLogs.length === 0) {
      return <button
        className="btn btn-sm btn-link p-0"
        onClick={() => handleShowHide(item)}
      >
        Edit
      </button>
    }

    tmLogs = timeLogInvoices.filter(
      (x) =>
        x.timeLogId === item.id &&
        x.status === true
    )

    if (tmLogs.length === 0) {
      return <button
        className="btn btn-sm btn-link p-0 text-underline"
        onClick={() => handleShowHide(item)}
      >
        Edit
      </button>
    }
  }

  const getMonthlyPeriod = (obj) => {
    if (!obj?.workingHrs) return "";
    const wt = JSON.parse(obj.workingHrs);
    const sortWt = wt.sort((a, b) => new Date(a.start) - new Date(b.start));
    if (sortWt.length > 0) {
      return (
        <div>
          <Moment format="MMM Do YYYY">{sortWt[0].start}</Moment> -{" "}
          <Moment format="MMM Do YYYY">
            {sortWt[sortWt.length - 1].type === "m"
              ? getMonthLastDay(sortWt[sortWt.length - 1].start)
              : sortWt[sortWt.length - 1].start}
          </Moment>
        </div>
      );
    }
    return "";
  };

  const handleNotesComments = (type, obj) => {
    if (type === "save") {
      setTimeLogObj(null);
      setNoteShowHide(false);
    } else {
      setTimeLogObj(obj);
      setNoteShowHide(!noteShowHide);
    }
  };

  const handlePayShowHide = (obj) => {
    setPayShowHide(!payShowHide);
    setTimeLogObj(obj);
  };

  const handleSaveProjectStartDate = () => {
    dispatch(updateProjectStartDate(currentUser.id, projectStartDate));
  };

  const handleStartDate = ({ currentTarget }) => {
    setProjectStartDate(currentTarget.value);
  };

  const handleShowHide = (obj) => {
    if (obj === "save") {
      setTimeLogObj("");
      setShowHide(false);
      dispatch(loadTimeLogs(currentUser.id))
    } else {
      setShowHide(!showHide);
      setTimeLogObj(obj);
    }
  };

  const handleDeleteTimeSheetDoc = async (id) => {
    const result = await getConfirmAlert("Warning", "Are you sure you want to delete this Time Sheet?", "warning");
    if (result.isConfirmed) {
      dispatch(deleteTimeSheetDoc(id));
    }
  }

  useEffect(() => {
    if (timeSheetDelStatusSelector) {
      getAlert("", "Deleted Successfully", "success");
      dispatch(loadTimeLogs(currentUser.id));
    }
  }, [timeSheetDelStatusSelector])

  let startDt = projectStartDate;
  if (startDt === "") startDt = new Date();
  let pgTitle = "Time Log Management";
  if (entityType !== "C2C Basis")
    pgTitle = "Time Log and Payments";

  return (
    <React.Fragment>
      <div className="col-md-12 mt-2">
        {/* <li className="list-group-item p-2 mb-2">
          <p className="text-red">
            Enter your daily hours logged for each week and upload the
            completed /signed time sheets. Once you enter the hours and upload
            the time sheet all entities as applicable (except the Agency at
            which you are working) will see it in real-time, and this includes
            your employers/subcontractors, if any.
          </p>
          <p className="text-red mt-2">
            Please ensure to log hours and upload time sheets on time to avoid
            any delays in raising invoices to the Agencies.
          </p>
        </li> */}
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <div className="row">
              <div className="col-md-4">
                <i className="fa fa-square wf-phase6-box mr-2" aria-hidden="true"></i>
                <span className="h3">
                  {pgTitle}
                </span>
              </div>
              <div className="col-md-8 text-right">
                <button
                  className="btn btn-sm btn-outline-default"
                  onClick={() => handleShowHide("")}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i> Add Time Log
                </button>
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="form-inline mb-2">
              <label htmlFor="">Date started on the project</label>
              {projectStDt !== "" ?
                <>
                  <input type="text"
                    className="form-control form-control-sm ml-2 mr-2"
                    disabled="true"
                    value={new Date(projectStDt).toISOString().substring(0, 10)} />
                  <button
                    className="btn btn-sm btn-outline-default"
                    onClick={() => setProjectStDt("")}
                  >
                    <i className="fa fa-edit" aria-hidden="true"></i> Edit
                  </button>

                </> :
                <>
                  <input
                    type="date"
                    className="form-control form-control-sm ml-2 mr-2"
                    value={new Date(startDt).toISOString().substring(0, 10)}
                    onChange={handleStartDate}
                  />
                  <button
                    className="btn btn-sm btn-outline-default"
                    onClick={handleSaveProjectStartDate}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i> Save
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => setProjectStDt(currentStDt)}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                </>}

            </div>
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-striped mb-2 border">
                <thead className="thead-light">
                  <tr>
                    <th>Year</th>
                    <th>Month</th>
                    <th>Period</th>
                    <th>Time<br />Logged</th>
                    <th>Total Hours<br />Total Days</th>
                    <th>Approved<br />Time Sheet
                    </th>
                    {entityType !== "W2 Basis" && <th>Status</th>}
                    <th>Notes<br />from IPCS</th>
                    {entityType === "W2 Basis" ?
                      <th>
                        Payment Date and Time<br />
                        Deduction ($)<br />
                        Payment ($)
                      </th> :
                      <th>
                        Payment Date and Time<br />
                        Payment ($)
                      </th>
                    }
                    {entityType === "W2 Basis" && (
                      <th>
                        Per Diem Rate ($)
                        <br />
                        Per Diem Amount ($)
                      </th>
                    )}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {timeLogs.map((item) => (
                    <tr>
                      <td>{item.year}</td>
                      <td>{getMonthName(item.month)}</td>
                      <td>{getMonthlyPeriod(item)}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-link p-0"
                          onClick={() => handleShowHide(item)}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        {getTotalHours(item.workingHrs)}
                        <br />
                        {getTotalDays(item.workingHrs)}{getTotalDays(item.workingHrs) > 1 ? " Days" : " Day"}
                      </td>
                      <td className="p-2">
                        {item.docList?.map((doc) => (
                          <li className="list-group-item p-0">
                            <td width="90%" className="p-0 border-0">
                              <button
                                className="btn btn-sm btn-link"
                                onClick={() => handleDownloadFile(doc)}
                              >
                                {doc.documentName}
                              </button>
                            </td>
                            <td className="text-right border-0 p-0">
                              <button
                                className="btn btn-sm"
                                title="Delete Timesheet"
                                onClick={() => handleDeleteTimeSheetDoc(doc.id)}
                              >
                                <i
                                  className="fa fa-trash text-red"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </td>
                          </li>
                        ))}
                      </td>
                      {entityType !== "W2 Basis" && (
                        <td>
                          {getInvoiceStatus(item)}
                        </td>
                      )}
                      <td>
                        <button
                          className="btn btn-sm btn-link p-0"
                          onClick={() => handleNotesComments("", item)}
                        >
                          View
                        </button>
                      </td>
                      {/* {entityType === "W2 Basis" ? ( */}
                      <td>
                        {getPaymentStatus(item)}
                      </td>
                      {/* ) : */}
                      {/* (
                          <td>
                            {getPaymentStatus(item)}
                          </td>
                        )
                      } */}
                      {entityType === "W2 Basis" && (
                        <td>
                          {getPerDiemStatus(item)}
                        </td>
                      )}
                      <td>
                        {getTimeLogStatus(item)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <AddNewTimeLog
        showHide={showHide}
        handleShowHide={handleShowHide}
        selectedObj={timeLogObj}
        timeLogInvoices={timeLogInvoices}
        allTimeLogs={timeLogsSelector}
        candidateObj={candidateObj}
      ></AddNewTimeLog>

      <AddEditNotes
        noteShowHide={noteShowHide}
        handleNoteShowHide={handleNotesComments}
        notesType={notesType}
        candidateObj={candidateObj}
        timeLogObj={timeLogObj}
      ></AddEditNotes>

      <CandidatePay
        payShowHide={payShowHide}
        handlePayShowHide={handlePayShowHide}
        selectedObj={timeLogObj}
        candidateObj={candidateObj}
      ></CandidatePay>

      {/* <AddTimeLog
        showHideTimeLog={showHideTimeLog}
        handleShowHideTimeLog={handleShowHideTimeLog}
        appTasklist={appTasklist}
        workflowTask={workflowTask}
      ></AddTimeLog> */}
    </React.Fragment>
  );
}
